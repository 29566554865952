import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Select,
    Upload,
    Button,
    Image,
    Spin,
    message,
    notification,
    Row,
    Col,
    Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Resizer from 'react-image-file-resizer';
import imageDefault from '../../../assets/img/png/camara.ico';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { addGroupMenu } from '../../../api/menu';
import { userLogOut } from '../../../utils/general';

import './GroupForm.scss';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const GroupForm = (props) => {
    const { setIsVisible, menu, setMenu } = props;

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const [image, setImage] = useState({
        imageUrl: '',
    });

    const { imageUrl } = image;

    const { Option } = Select;

    const [filesList, setFileList] = useState({});

    const [loadingForm, setLoadingForm] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;

        formData['CompanyId'] = companyId;

        let includeImage = false;

        let imageGroupJSON;

        if (values.upload !== undefined) {
            includeImage = true;

            if (values.upload.length > 0) {
                let image = values.upload[0];

                const isJpgOrPng =
                    image.type === 'image/jpeg' ||
                    image.type === 'image/png' ||
                    image.type === 'image/jpg';

                if (!isJpgOrPng) {
                    notification['error']({
                        message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
                        // placement: 'bottomRight'
                    });
                    setLoadingForm(true);
                    return false;
                }

                const isLt2M = image.size / 1000 < 250;
                let imageResize = '';
                if (!isLt2M) {
                    notification['warning']({
                        message:
                            'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
                        placement: 'bottomRight',
                        duration: 3,
                    });
                    // message.warning('¡La imagen supera los 250kb, sera reducida de tamaño.');
                    imageResize = await resizeFile(image.originFileObj);
                }

                let posData64;

                let posType = image.type.indexOf('/') + 1;
                if (isLt2M) {
                    posData64 = image.thumbUrl.indexOf(',') + 1;
                    formData['ImageData'] = image.thumbUrl.substr(posData64);
                } else {
                    posData64 = imageResize.indexOf(',') + 1;
                    formData['ImageData'] = imageResize.substr(posData64);
                }
                // let posData64 = imageResize.indexOf(",")+1;
                // let posData64 = image.thumbUrl.indexOf(",")+1;

                // formData["ImageData"] = imageResize.substr(posData64);
                // formData["ImageData"] = image.thumbUrl.substr(posData64);

                formData['Extension'] = image.type.substr(posType);

                imageGroupJSON = {
                    companyId: companyId,
                    extension: image.type.substr(posType),
                    fileLocation: null,
                    fileName: `${companyId}_Group_${values.Name}`,
                    fileType: 'Image',
                    imageData: isLt2M
                        ? image.thumbUrl.substr(posData64)
                        : imageResize.substr(posData64),
                    imageId: 1,
                };

                // setLoadingForm(false);
                // return false;
            }
        }

        const result = await addGroupMenu(token, formData);

        if (result === undefined) {
            let messageError =
                'Ocurrio un problema al crear un nuevo grupo, Intentelo mas tarde';

            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);

            setTimeout(() => {
                setIsVisible(false);
            }, 100);

            return true;
        }

        if (result?.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado',
            });
            setLoadingForm(false);

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Grupo agregado exitosamente',
            });

            // let start = result.description.indexOf("grupo")+6;
            // let end = result.description.indexOf(" para");

            // const idGroup = result.description.slice(start, end);
            const idGroup = result.result.groupId;

            const categoryJSON = {
                categories: [],
                company: null,
                companyId: companyId,
                groupId: parseInt(idGroup),
                groupImage: includeImage ? imageGroupJSON : null,
                groupImageId: includeImage ? 1 : null,
                name: values.Name,
                priority: values.Priority,
            };
            menu.push(categoryJSON);

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menu.sort(compare);

            setMenu(menu);

            setLoadingForm(false);

            form.resetFields();
            setImage({
                imageUrl: null,
            });

            setTimeout(() => {
                setIsVisible(false);
            }, 100);
        } else {
            let messageError =
                'Ocurrio un problema al crear un nuevo grupo, Intentelo otra vez';

            if (result.statusCode === 404) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1000 < 250;
        if (!isLt2M) {
            message.warning(
                'La imegen debe ser menos de 250 kb para que no pierda calidad'
            );
        }

        //   return isJpgOrPng && isLt2M;

        //   console.log(file);

        return false;
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                'JPEG',
                85,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            fileList = fileList.slice(-1);
            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) =>
                setImage({
                    imageUrl: imageUrl,
                })
            );
        } else {
            setImage({
                imageUrl: null,
            });
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    function Cancel() {
        form.resetFields();
        setImage({
            imageUrl: null,
        });
        setIsVisible(false);
    }

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="groupName"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre del grupo"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre del grupo" />
                </Form.Item>

                <div className="group-form__container-image">
                    <Image
                        width={200}
                        src={imageUrl ? imageUrl : imageDefault}
                    />
                </div>

                <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra="Imagen adjunta"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una imagen',
                        },
                    ]}
                >
                    <Upload
                        name="logo"
                        beforeUpload={beforeUpload}
                        listType="picture"
                        multiple={false}
                        onChange={handleChange}
                        fileList={filesList}
                    >
                        <Button icon={<UploadOutlined />}>
                            Click para cargar la imagen
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="Priority"
                    label="Prioridad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione una prioridad" allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default GroupForm;
