import { basePath } from '../api/config';
import { notification } from 'antd';

function displayConfirmNotification() {
    if ('serviceWorker' in navigator) {
        var options = {
            body: 'You successfully subscribed to our Notification service!',
            icon: '../assets/icons/icon-96x96.png',
            image: '../assets/img/jpg/seafood.jpg',
            dir: 'ltr',
            lang: 'en-US', // BCP 47,
            vibrate: [100, 50, 200],
            badge: '../assets/icons/icon-96x96.png',
            tag: 'confirm-notification',
            renotify: true,
            data: '/',
            // data: '../pages/Client/Client.js'
            // data: '../restaurantesqa/TypeOrder/TypeOrder'
            /* actions: [
                 { action: 'confirm', title: 'Okay'},
                 { action: 'cancel', title: 'Cancel' }
             ]*/
        };

        navigator.serviceWorker.ready.then(function (swreg) {
            swreg.showNotification('¡Suscrito exitosamente!', options);
        });
    }
}

function configurePushSub(clientId, roleId, companyId, token) {
    if (!('serviceWorker' in navigator)) {
        return;
    }
    var reg;
    navigator.serviceWorker.ready
        .then(function (swreg) {
            console.log('ready');

            reg = swreg;
            return swreg.pushManager.getSubscription();
        })
        .then(function (sub) {
            if (sub === null) {
                // Create a new subscription
                console.log('Create a new subscription');

                var vapidPublicKey =
                    'BOHrTuilcFOCIzyurjm9_S8BJ7cwFLgrf9HwCV0Wi6knG1zDS8G83z3x0ux6GCJZOCxuL_Js9dP4iMURkEzA-aY';
                var convertedVapidPublicKey = urlBase64ToUint8Array(
                    vapidPublicKey
                );
                reg.pushManager
                    .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidPublicKey,
                    })
                    .then(function (newSub) {
                        var bodyRequest = newSub.toJSON();

                        return fetch(
                            `${basePath}/Subscription/AddSubscription`,
                            {
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                    Accept: 'application/json',
                                },
                                body: JSON.stringify({
                                    CompanyId: companyId,
                                    UserId: clientId,
                                    Auth: bodyRequest['keys']['auth'],
                                    P256dh: bodyRequest['keys']['p256dh'],
                                    EndPoint: bodyRequest['endpoint'],
                                    UserType: roleId,
                                }),
                            }
                        ).then(function (res) {
                            console.log('Created a subscription' + res);
                            displayConfirmNotification();
                            return sub;
                        });
                    });
            } else {
                // We have a subscription
                console.log('We have a subscription' + sub);
                return sub;
            }
        })

        .catch(function (err) {
            console.log(err);
        });
}

export function askForNotificationPermission(
    clientId,
    roleId,
    companyId,
    token
) {
    if (getMobileOperatingSystem() !== 'iOS') {
        if ('Notification' in window) {
            // API supported
            Notification.requestPermission(function (result) {
                if (result !== 'granted') {
                    console.log('No permission granted');
                } else {
                    //Hide the button
                    //displayConfirmNotification();
                    configurePushSub(clientId, roleId, companyId, token);
                }
            });
        } else {
            // API not supported
            console.log('API not supported');
        }
    }
}

export function notificationActive() {
    if (getMobileOperatingSystem() !== 'iOS') {
        if ('Notification' in window) {
            // API supported
            Notification.requestPermission(function (result) {
                console.log(result);

                if (result === 'granted') {
                    notification['success']({
                        message: 'Notificaciones activadas',
                    });
                } else if (result === 'denied') {
                    notification['error']({
                        message: 'Notificaciones bloqueadas. ',
                    });
                }
            });
        } else {
            // API not supported
            console.log('API not supported');
        }
    }
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone debe ir primero porque su UA tambien contiene "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'desconocido';
}

function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
