import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Typography,
    Row,
    Col,
    Avatar,
    Button,
    List,
    Skeleton,
    Modal as ModalAntd,
    notification,
} from 'antd';
import {
    CaretRightOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import Modal from '../../../../components/General/Modal';
import EditRunnerGroup from '../../../../components/Admin/Runners/EditRunnerGroup';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import AddRunnerForm from '../AddRunnerForm';
import { deleteCategoryMenu } from '../../../../api/menu';
import { getRunnerGroup, deleteGroupRunner } from '../../../../api/runner';
import useAuth from '../../../../hooks/useAuth';
import './RunnerGroupList.scss';
import RunnersList from '../RunnersList/RunnersList';
import { formatMoney } from '../../../../utils/general';
import EditRunnerForm from '../../../../components/Admin/Runners/EditRunnerForm';
export default function RunnerGroupList(props) {
    const { Panel } = Collapse;

    const { runnerGroupsList, loading } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [titleModal, setTitleModal] = useState('Titulo');
    const [contextModal, setContextModal] = useState(null);
    let [groupAdmin, setGroupAdmin] = useState([]);

    let [updateGroups, setUpdateGroups] = useState(false);
    const [groupSearches, setGroupSearches] = useState([]);
    const [loadingItems, setLoadingItems] = useState(loading);
    const [activeKey, setActiveKey] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const [runners, setRunners] = useState([]);
    const [roleUser, setRoleUser] = useState('');

    const { user } = useAuth();

    const { confirm } = ModalAntd;

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        if (roleId === 'Admin') {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        setGroupAdmin(runnerGroupsList);
        return () => {
            setGroupAdmin([]);
        };
    }, [runnerGroupsList]);

    useEffect(() => {
        setLoadingItems(loading);
    }, [loading]);

    useEffect(() => {
        setGroupAdmin(groupAdmin);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateGroups]);

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const showModal = (title, element, type, groupId) => {
        setIsModalVisible(true);
        setTitleModal(title);

        switch (type) {
            case 'Edit-Group':
                setContextModal(
                    <EditRunnerGroup
                        groupAdmin={runnerGroupsList}
                        setGroupAdmin={setGroupAdmin}
                        element={element}
                        setIsModalVisible={setIsModalVisible}
                    />
                );

                break;

            case 'Add-Category':
                setContextModal(
                    <AddRunnerForm
                        groupAdmin={runnerGroupsList}
                        setGroupAdmin={setGroupAdmin}
                        element={element}
                        setIsModalVisible={setIsModalVisible}
                        destroyOnClose
                    />
                );
                break;

            case 'Edit-Category':
                setContextModal(
                    <EditRunnerForm
                        groupAdmin={runnerGroupsList}
                        setGroupAdmin={setGroupAdmin}
                        element={element}
                        setIsModalVisible={setIsModalVisible}
                    />
                );
                break;
            default:
                break;
        }
    };

    const showDeleteConfirm = (title, idGroup, type, idCategory) => {
        confirm({
            title: `Estas seguro que deseas borrar ${title}`,
            icon: <ExclamationCircleOutlined />,
            content: 'No podras recuperarlo despues...',
            onOk() {
                setUpdateGroups(false);

                switch (type) {
                    case 'order':
                        deleteGroup(title, idGroup);
                        break;
                    case 'category':
                        deleteCategory(title, idGroup, idCategory);
                        break;

                    default:
                        break;
                }
            },
            onCancel() {},
        });
    };

    const deleteGroup = (title, idGroup) => {
        deleteGroupRunner(token, idGroup)
            .then((res) => {
                if (res?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1200);

                    return false;
                }
                if (res?.statusCode !== 200) {
                    let msg = `Ocurrio un porblema, ${title} no se pudo eliminar`;
                    if (
                        res?.statusCode === 409 ||
                        res?.statusCode === 400 ||
                        res?.statusCode === 404
                    ) {
                        msg = res?.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                    setUpdateGroups(true);

                    return false;
                } else {
                    const found = groupAdmin.findIndex(
                        (obj) => obj.id === idGroup
                    );
                    groupAdmin.splice(found, 1);
                    setGroupAdmin(groupAdmin);
                    setUpdateGroups(true);

                    notification['success']({
                        message: `Se elimino con exito ${title}`,
                    });

                    return true;
                }
            })
            .catch((err) => {
                return false;
            });
    };

    const deleteCategory = (title, idGroup, idCategory) => {
        let data = {
            CompanyId: companyId,
            id: idGroup,
            CategoryId: idCategory,
        };

        deleteCategoryMenu(token, data)
            .then((res) => {
                if (res?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1200);

                    return false;
                }
                if (res.message) {
                    notification['error']({
                        message: `Ocurrio un porblema, ${title} no se pudo eliminar`,
                    });
                    setUpdateGroups(true);

                    return false;
                } else {
                    const found = groupAdmin.findIndex(
                        (obj) => obj.id === idGroup
                    );
                    const foundCat = groupAdmin[found].categories.findIndex(
                        (cat) => cat.categoryId === idCategory
                    );
                    groupAdmin[found].categories.splice(foundCat, 1);
                    setGroupAdmin(groupAdmin);
                    setUpdateGroups(true);

                    notification['success']({
                        message: `Se elimino con exito la ${title}`,
                    });

                    return true;
                }
            })
            .catch((err) => {
                return false;
            });
    };

    const callbackGroup = (key) => {
        if (key !== undefined) {
            let idGroup = parseInt(key);
            const foundIdGroup = groupSearches.find((id) => id === idGroup);
            if (foundIdGroup === undefined) {
                setLoadingItems(true);
                // let dataApi = {
                //     id: idGroup,
                // };

                getRunnerGroup(token, key).then((resp) => {
                    if (resp === undefined) {
                        setActiveKey(key);
                    } else if (resp !== undefined) {
                        if (resp?.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return false;
                        }
                        setGroupSearches([...groupSearches, idGroup]);
                        const found = groupAdmin.findIndex(
                            (item) => item.id === resp.id
                        );
                        groupAdmin[found] = resp;
                        setGroupAdmin(groupAdmin);
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }
                    setLoadingItems(false);
                });
            } else {
                setActiveKey(key);
            }
        }
    };

    const listData = [];

    for (let i = 0; i < 5; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }
    return (
        <>
            {loadingItems !== true && (
                // <Spin spinning={loadingItems} tip="Cargando..." >
                <Collapse
                    collapsible="disabled"
                    bordered={false}
                    accordion
                    defaultActiveKey={[activeKey]}
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="menu-collapse"
                    expandIconPosition="right"
                    onChange={callbackGroup}
                >
                    {groupAdmin &&
                        groupAdmin !== undefined &&
                        groupAdmin.length > 0 &&
                        groupAdmin.map((ele) => {
                            return (
                                <Panel
                                    header={
                                        <>
                                            <TitleOrder name={ele.name} />
                                        </>
                                    }
                                    key={ele.id}
                                    collapsible="disabled"
                                    className="menu_panel"
                                >
                                    <ButtonIcon
                                        category="order"
                                        showModal={showModal}
                                        priority={ele.priority}
                                        element={ele}
                                        showDeleteConfirm={showDeleteConfirm}
                                        isAdmin={isAdmin}
                                        setIsModalVisible={setIsModalVisible}
                                        setGroupAdmin={setGroupAdmin}
                                    />
                                    {ele.companyRunners && (
                                        <RunnersList
                                            runners={ele.companyRunners}
                                            setGroupAdmin={setGroupAdmin}
                                            groupAdmin={groupAdmin}
                                            setRunners={setRunners}
                                        />
                                    )}
                                </Panel>
                            );
                        })}
                </Collapse>
                // </Spin>
            )}

            {loadingItems === true && (
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item key={item.title}>
                            <Skeleton loading={loadingItems} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                {item.content}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                // destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
}

function TitleOrder(props) {
    const { Title } = Typography;
    const { name } = props;
    return (
        <Row gutter={[16, 16]} className="menu__header">
            <Col
                span={16}
                className={['menu__header-right', 'show-menu-admin']}
            >
                <Title level={3}>{name}</Title>
            </Col>
        </Row>
    );
}

function ButtonIcon(props) {
    const { category, showModal, element, showDeleteConfirm, isAdmin } = props;

    let titleModalEdit = category === 'order' ? 'Editar Grupo' : 'Editar Grupo';
    let titleModalAdd =
        category === 'order' ? 'Agregar Runner' : 'Agregar Runner';

    let actionEdit = '';
    let actionAdd = '';
    let titleDelete = '';
    let idCategory = 0;

    if (category === 'order') {
        actionEdit = 'Edit-Group';
        actionAdd = 'Add-Category';
        titleDelete = `el grupo: ${element.name}`;
    } else if (category === 'category') {
        actionEdit = 'Edit-Category';
        titleDelete = `la categoría: ${element.name}`;
        idCategory = element.categoryId;
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row className="buttons__containers">
                    <div>
                        <Row>
                            <h4>Distancia base (Km): </h4>
                            <div className="ant-list-item-meta-description">
                                {element.distanceKm} km
                            </div>
                        </Row>
                        <Row>
                            <h4>Precio base: </h4>
                            <div className="ant-list-item-meta-description">
                                {' '}
                                ${formatMoney(element.pricePerKm)}
                            </div>
                        </Row>
                        <Row>
                            <h4>Precio por kilómetro excedido: </h4>
                            <div className="ant-list-item-meta-description">
                                {' '}
                                ${formatMoney(element.exceededPricePerKm)}
                            </div>
                        </Row>
                        <div className="list-users__header">
                            <Button
                                type="primary"
                                onClick={() =>
                                    showModal(titleModalAdd, element, actionAdd)
                                }
                                icon={<UserAddOutlined />}
                            >
                                Nuevo Runner
                            </Button>
                        </div>
                    </div>

                    <Col span={4}>
                        <Button
                            className="btn-icons"
                            type="primary"
                            shape="circle"
                            size={category === 'order' ? 'large' : 'default'}
                            icon={<EditOutlined />}
                            onClick={() =>
                                showModal(titleModalEdit, element, actionEdit)
                            }
                        />
                    </Col>
                    {isAdmin && (
                        <Col span={4}>
                            <Button
                                className="btn-icons"
                                type="primary"
                                shape="circle"
                                danger
                                size={
                                    category === 'order' ? 'large' : 'default'
                                }
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                    showDeleteConfirm(
                                        titleDelete,
                                        element.id,
                                        category,
                                        idCategory
                                    )
                                }
                            />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
}
