import React from 'react';
import { Button, Affix } from 'antd';
import { LeftOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';

import './MenuTop.scss';

export default function MenuTop(props) {

    const {title, backIcon, icon, action, onAction} = props;
    return(
        <Affix offsetTop >
        <div className="menu-top">
            <div className="menu-top__left">
                {/* boton hamburguesa */}
                {backIcon && (
                    <Button className="menu-top__icon" size={"small"}>
                        <LeftOutlined />
                    </Button>
                )}
            </div>

            <div className="menu-top__center">
                <h1>{title}</h1>
            </div>

            <div className="menu-top__right">
                {action && (
                    <Button  
                        className="action-btn" 
                        type="dashed" 
                        shape="square" 
                        icon={(icon && icon === 'add') ? <PlusOutlined /> : <SearchOutlined />}
                        onClick={onAction} 
                    >     
                    </Button>
                )}  
            </div>
            
        </div>
      </Affix>

    )
}