import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    List,
    Card,
    Row,
    Col,
    Typography,
    Tag,
    Divider,
    Collapse,
    Button,
    Modal as ModalAntd,
    Spin,
    notification,
} from 'antd';
import {
    ExclamationCircleOutlined,
    PoweroffOutlined,
    CreditCardOutlined,
    InfoCircleOutlined,
    EnvironmentOutlined,
    ReloadOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { userLogOut, formatMoney } from '../../../utils/general';
import { getOrdersRunner, takeOrderApi } from '../../../api/runner';
import Modal from '../../../components/General/Modal';
import ConnectedRunner from '../ConnectedRunner/ConnectedRunner';
import Logo from '../../../assets/img/png/logoControlB.png';

import './OrdersList.scss';

const OrdersList = () => {
    let history = useHistory();
    const token = getAccessTokenApi();
    const [ordersAvailables, setOrdersAvailables] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isChangeStatus, setIsChangeStatus] = useState(false);
    const { Title, Text, Paragraph } = Typography;
    const { Panel } = Collapse;
    const { confirm } = ModalAntd;

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getOrdersRunnerHandler();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeStatus]);

    const getOrdersRunnerHandler = async () => {
        let ordenes = [];
        setLoading(true);
        const result = await getOrdersRunner(token);
        console.log(result);
        if (result) {
            if (result.statusCode === 200) {
                ordenes = result.result;
                setOrdersAvailables(ordenes);
                setIsChangeStatus(false);
            } else if (result.statusCode === 500) {
                notification['error']({
                    message: 'Ocurrio un error intentelo mas tarde.',
                });
            }
        } else {
            notification['warning']({
                message: 'No esta autorizado para este apartado aun.',
            });

            setTimeout(() => {
                history.push('/runner');
            }, 500);
        }
        setLoading(false);
    };

    function showConfirm(orden) {
        confirm({
            title: `¿Estás seguro que deseas aceptar la órden: ${orden.orderNum}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'No podra deshacer esta acción después.',
            onOk() {
                takeOrderHandler(orden);
            },
            onCancel() {},
        });
    }

    const takeOrderHandler = async (order) => {
        const { companyId, orderId } = order;
        const data = {
            companyId: companyId,
            OrderId: orderId,
        };

        const response = await takeOrderApi(token, data);

        if (response) {
            if (response.statusCode === 200) {
                const { id } = response.result;
                history.push(`/runner/delivery/${id}`);
            } else {
                if (response.statusCode === 409) {
                    notification['error']({
                        message: response.description,
                    });
                } else {
                    if (response.statusCode === 400) {
                        notification['error']({
                            message: response.description,
                        });
                        setIsChangeStatus(true);
                    } else {
                        notification['error']({
                            message: 'Ocurrio un error, intentelo mas tarde.',
                        });
                    }
                }
            }
        } else {
            notification['error']({
                message:
                    'Ocurrio un error, intentelo mas tarde o verifique su conexión a internet.',
            });
        }
    };

    return (
        <div className="list-active-orders">
            <div className="list-active-orders__container-button">
                <Button
                    icon={<PoweroffOutlined />}
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                >
                    Conectarme / Desconectarme{' '}
                </Button>
            </div>
            <div className="list-active-orders__container-button">
                <Button
                    type="primary"
                    ghost
                    icon={<ReloadOutlined />}
                    onClick={() => setIsChangeStatus(true)}
                >
                    Actualizar
                </Button>
            </div>
            <Divider>
                <h3>Órdenes a recolectar</h3>
            </Divider>
            <Spin spinning={loading} tip="Cargando...">
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    pagination={{
                        onChange: (page) => {},
                        pageSize: 3,
                    }}
                    itemLayout="horizontal"
                    dataSource={ordersAvailables}
                    renderItem={(item) => (
                        <List.Item>
                            <Card className="card">
                                <Row>
                                    <Col span={1}>
                                        <div className="avatar-company">
                                            {item.companyImage && (
                                                <img
                                                    className="logo"
                                                    src={`data:image/${item.companyImage.extension};base64, ${item.companyImage.imageData}`}
                                                    alt="logo de la compañia"
                                                />
                                            )}
                                            {item.companyImage === null && (
                                                <img
                                                    className="logo"
                                                    src={Logo}
                                                    alt="logo de la compañia"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col
                                        span={23}
                                        className="align-content-order"
                                    >
                                        <div className="text-right">
                                            <Tag
                                                className="order-number"
                                                color="#ff4d4f"
                                            >
                                                {`Orden: ${item.orderNum}`}
                                            </Tag>
                                        </div>
                                        <Title level={4}>
                                            {`Comisión: $${formatMoney(
                                                item.commission
                                            )}`}
                                            {/* {`Comisión: $${item.commission}`} */}
                                        </Title>
                                        <Text type="secondary">
                                            {' '}
                                            {`Distancia: ${item.distance} km`}
                                        </Text>

                                        <Paragraph type="secondary">
                                            Tiempo de preparación:{' '}
                                            {item.preparationTime} Minutos{' '}
                                        </Paragraph>
                                    </Col>
                                    <Collapse
                                        ghost
                                        className="description-order"
                                    >
                                        <Panel
                                            className="padding-panel-order"
                                            header="Más Información"
                                            key="1"
                                        >
                                            <Row gutter={[0, 10]}>
                                                <Col>
                                                    <Text type="secondary">
                                                        <Text strong>
                                                            <CreditCardOutlined
                                                                style={{
                                                                    marginRight: 6,
                                                                }}
                                                            />{' '}
                                                            Método de pago:
                                                        </Text>{' '}
                                                        {item.payType === 1
                                                            ? 'Efectivo'
                                                            : 'Tarjeta'}
                                                    </Text>
                                                </Col>
                                                <Col>
                                                    <Text type="secondary">
                                                        <Text strong>
                                                            <ShopOutlined
                                                                style={{
                                                                    marginRight: 6,
                                                                }}
                                                            />
                                                            Dirección de
                                                            Recogida:
                                                        </Text>{' '}
                                                        {item.companyAddress}
                                                    </Text>
                                                </Col>
                                                <Col>
                                                    <Text type="secondary">
                                                        <Text strong>
                                                            <EnvironmentOutlined
                                                                style={{
                                                                    marginRight: 6,
                                                                }}
                                                            />
                                                            Dirección de
                                                            Entrega:
                                                        </Text>{' '}
                                                        {item.clientAddress}
                                                    </Text>
                                                </Col>
                                                <Text type="secondary">
                                                    {' '}
                                                    <Text strong>
                                                        <InfoCircleOutlined
                                                            style={{
                                                                marginRight: 6,
                                                            }}
                                                        />
                                                        Indicaciones de
                                                        recogida:
                                                    </Text>{' '}
                                                    {item.indications}
                                                </Text>
                                                <Col xs={24}>
                                                    <Button
                                                        className="margin-button-order"
                                                        onClick={() =>
                                                            showConfirm(item)
                                                        }
                                                        block
                                                        type="primary"
                                                    >
                                                        Aceptar órden
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
            </Spin>
            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={'Conectarme / Desconectarme'}
                footer={false}
                // destroyOnClose
            >
                <ConnectedRunner
                    setIsChangeStatus={setIsChangeStatus}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </div>
    );
};

export default OrdersList;
