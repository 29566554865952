import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Table,
    DatePicker,
    Button,
    Space,
    Typography,
    Row,
    Col,
    notification,
    Tag,
    Divider,
} from 'antd';
import {
    EnvironmentTwoTone,
    EyeOutlined,
    FieldTimeOutlined,
    CreditCardOutlined,
    CarOutlined,
    DollarCircleTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import { formatMoney, messageError } from '../../../utils/general';
import { getAccessTokenApi } from '../../../api/auth';
import { getRecordsRunnerApi, getCompaniesRunner } from '../../../api/runner';

const Records = () => {
    const isMounted = useRef(true);
    let history = useHistory();
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [records, setRecords] = useState([]);
    const [companiesRunner, setCompaniesRunner] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateRecord, setDateRecod] = useState({
        startDate: moment().subtract(7, 'days').unix(),
        endDate: moment().unix(),
    });
    const token = getAccessTokenApi();
    const { RangePicker } = DatePicker;
    const { Paragraph, Text } = Typography;

    useEffect(() => {
        getData();

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRecord]);

    const getData = async () => {
        setLoading(true);
        const filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}`;

        const [recordsArray, companyRunner] = await Promise.all([
            getRecordsRunnerApi(token, filters),
            getCompaniesRunner(token),
        ]);

        if (recordsArray) {
            if (recordsArray.statusCode === 200) {
                recordsArray.result.forEach((element, index) => {
                    element['key'] = index;
                });

                setRecords(recordsArray.result);
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }

        if (companyRunner) {
            if (companyRunner.statusCode === 200) {
                const formatCompanies = [];
                companyRunner.result.forEach((company) => {
                    const data = {
                        text: company.companyName,
                        value: company.companyName,
                    };
                    formatCompanies.push(data);
                });
                setCompaniesRunner(formatCompanies);
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }

        setLoading(false);
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    // const clearFilters = () => {
    //     setFilteredInfo({});
    // };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    // const  setAgeSort = () => {
    //     this.setState({
    //       sortedInfo: {
    //         order: 'descend',
    //         columnKey: 'age',
    //       },
    //     });
    // };

    const columns = [
        {
            title: 'No Orden',
            dataIndex: 'orderNum',
            key: 'orderNum',
            sorter: (a, b) => a.orderNum - b.orderNum,
            sortOrder: sortedInfo.columnKey === 'orderNum' && sortedInfo.order,
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Establecimientos',
            dataIndex: 'companyName',
            key: 'companyName',
            filters: companiesRunner,
            filteredValue: filteredInfo.companyName || null,
            onFilter: (value, record) => record.companyName.includes(value),
            sorter: (a, b) => a.companyName.length - b.companyName.length,
            sortOrder:
                sortedInfo.columnKey === 'companyName' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Fecha',
            dataIndex: 'deliveredDate',
            key: 'deliveredDate',
            sorter: (a, b) => a.deliveredDate - b.deliveredDate,
            sortOrder:
                sortedInfo.columnKey === 'deliveredDate' && sortedInfo.order,
            ellipsis: true,
            width: 120,
            render: (deliveredDate) => (
                <p>{moment.unix(deliveredDate).format('L')}</p>
            ),
        },
        {
            title: 'Estatus de Pago',
            dataIndex: 'isPaidOut',
            key: 'isPaidOut',
            filters: [
                { text: 'Liquidado', value: true },
                { text: 'Pendiente', value: false },
            ],
            filteredValue: filteredInfo.isPaidOut || null,
            onFilter: (value, record) => record.isPaidOut === value,
            sorter: (a, b) => a.isPaidOut.length - b.isPaidOut.length,
            sortOrder: sortedInfo.columnKey === 'isPaidOut' && sortedInfo.order,
            ellipsis: true,
            render: (isPaidOut) => (
                <Tag color={isPaidOut === 1 ? 'green' : 'volcano'}>
                    {isPaidOut === 1 ? 'LIQUIDADO' : 'PENDIENTE'}
                </Tag>
            ),
        },
        {
            title: 'Comisión',
            dataIndex: 'deliveryCommission',
            key: 'deliveryCommission',
            sorter: (a, b) => a.deliveryCommission - b.deliveryCommission,
            sortOrder:
                sortedInfo.columnKey === 'deliveryCommission' &&
                sortedInfo.order,
            ellipsis: true,
            width: 100,
            render: (deliveryCommission) => <p>${deliveryCommission}</p>,
        },
        {
            title: 'Estatus Entrega',
            dataIndex: 'deliveryStatus',
            key: 'deliveryStatus',
            filters: [
                { text: 'COMPLETADA', value: 5 },
                { text: 'CANCELADA', value: 6 },
            ],
            filteredValue: filteredInfo.deliveryStatus || null,
            onFilter: (value, record) => record.deliveryStatus === value,
            sorter: (a, b) => a.deliveryStatus.length - b.deliveryStatus.length,
            sortOrder:
                sortedInfo.columnKey === 'deliveryStatus' && sortedInfo.order,
            ellipsis: true,
            render: (deliveryStatus) => (
                <Tag color={deliveryStatus === 5 ? 'green' : 'red'}>
                    {deliveryStatus === 5 ? 'COMPLETADA' : 'CANCELADA'}
                </Tag>
            ),
        },

        {
            title: 'Ver detalle',
            dataIndex: 'deliveryId',
            key: 'x',
            fixed: 'right',
            width: 80,
            render: (deliveryId) => (
                <Button
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() =>
                        history.push(`/runner/delivery/${deliveryId}`)
                    }
                />
            ),
        },
    ];

    function onChangeDate(date, dateString) {
        console.log(date);
        if (date) {
            setDateRecod({
                startDate: date[0].unix(),
                endDate: date[1].unix(),
            });
        }
    }

    return (
        <div className="records">
            <Space style={{ marginBottom: 16 }}>
                {/* <Button onClick={clearFilters}>Clear filters</Button> */}
            </Space>
            <Row gutter={[10, 10]}>
                <Col xs={24} md={8}>
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="DD-MM-YYYY HH:mm"
                        onChange={onChangeDate}
                    />
                </Col>
                <Col xs={24} md={8}>
                    <Button onClick={clearAll}>Limpiar Filtros</Button>
                </Col>
                <Col span={24} xs={24} md={8}>
                    <Paragraph>
                        Búsqueda del:{' '}
                        <Text strong>
                            {moment
                                .unix(dateRecord.startDate)
                                .format('DD-MM-YYYY HH:mm')}{' '}
                        </Text>
                        al{' '}
                        <Text strong>
                            {moment
                                .unix(dateRecord.endDate)
                                .format('DD-MM-YYYY HH:mm')}
                        </Text>
                    </Paragraph>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Table
                loading={loading}
                columns={columns}
                dataSource={records}
                onChange={handleChange}
                scroll={{ x: 950 }}
                expandable={{
                    expandedRowRender: (record) => (
                        <InfoOrder record={record} />
                    ),
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
            />
        </div>
    );
};

export default Records;

function InfoOrder(props) {
    const { record } = props;
    const { Text, Paragraph } = Typography;

    return (
        <div>
            <Row>
                <Col xs={24} md={12} lg={8}>
                    {/* <Paragraph style={{ margin: 0 }}>{record.name}</Paragraph> */}
                    <Paragraph>
                        <Text strong>
                            <FieldTimeOutlined style={{ marginRight: 5 }} />
                            Hora de Solicitud:
                        </Text>{' '}
                        {moment
                            .unix(record.requestDate)
                            .format('DD/MM/YYYY h:mm a')}
                    </Paragraph>
                    <Paragraph>
                        <Text strong>
                            {' '}
                            <CreditCardOutlined style={{ marginRight: 5 }} />
                            Método de pago:{' '}
                        </Text>
                        {record.orderPayType === 1 ? 'Efectivo' : 'Tarjeta'}
                    </Paragraph>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Paragraph>
                        <Text strong>
                            <CarOutlined style={{ marginRight: 5 }} />
                            Km Recorridos:{' '}
                        </Text>
                        {record.traveledKm}Km
                    </Paragraph>
                    <Paragraph>
                        <Text strong>
                            <DollarCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ marginRight: 5 }}
                            />
                            Total de la Orden:{' '}
                        </Text>
                        ${formatMoney(record.orderTotal)}
                    </Paragraph>
                    {/* <Paragraph>
                        <Text strong>
                            <DollarCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ marginRight: 5 }}
                            />
                            Ganancia:{' '}
                        </Text>
                        ${formatMoney(40)}
                    </Paragraph> */}
                </Col>
                <Col>
                    <Tag
                        color={record.orderStatus === 6 ? 'green' : 'red'}
                        style={{ marginBottom: 20, textTransform: 'uppercase' }}
                    >
                        {record.orderStatus === 6
                            ? 'Orden Pagada'
                            : 'Pendiente del pago por parte del cliente '}
                    </Tag>
                </Col>
                <Col xs={24}>
                    <Text type="secondary" strong>
                        <EnvironmentTwoTone style={{ marginRight: 8 }} />
                        {record.clientAddress}
                    </Text>
                </Col>
            </Row>
        </div>
    );
}
