import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
    Layout,
    Result,
    Button,
    Row,
    Col,
    Typography,
    notification,
    // InputNumber,
} from 'antd';
// import { SendOutlined } from '@ant-design/icons';
import HeaderTop from '../../components/General/HeaderTop';
import { confirmEmailApi } from '../../api/user';
import { messageError } from '../../utils/general';

import './ConfirmEmail.scss';

const ConfirmEmail = (props) => {
    const { Content } = Layout;
    // const [code, setCode] = useState(null);

    useEffect(() => {
        let params = queryString.parse(props.location.search);
        console.log(params);
        validEmail(params);
        return () => {};
    }, [props.location.search]);

    const validEmail = async (user) => {
        console.log('email', user.email);
        console.log('token', user.token);
        const result = await confirmEmailApi(user.token, user.email);

        if (result) {
            if (result?.statusCode === 200) {
                notification['success']({
                    message: 'Email validado.',
                });
            } else {
                notification['error']({
                    message:
                        'Ocurrio un error al validar tu correo, intentelo mas tarde.',
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
    };

    return (
        <Layout className="confirm-email">
            <Content className="confirm-email__content">
                <HeaderTop
                    title={'Validar Cuenta'}
                    backIcon={true}
                    backUrl={'/login'}
                />

                <div className="confirm-email__container">
                    <Result
                        status="success"
                        title={<TitleResult />}
                        subTitle={<SubTitleResult phone={6621410318} />}
                        extra={[
                            // <InputNumber
                            //     key="code"
                            //     placeholder="Ingresa código"
                            //     value={code}
                            //     onChange={(value) => setCode(value)}
                            // ></InputNumber>,
                            // <Button
                            //     key="button"
                            //     icon={<SendOutlined />}
                            //     type="primary"
                            //     style={{ marginTop: 20 }}
                            //     disabled={!code}
                            // >
                            //     Enviat
                            // </Button>,
                            <Link key="link" to="/login">
                                <Button type="primary">Ir a Inicio</Button>
                            </Link>,
                        ]}
                    />
                </div>
            </Content>
        </Layout>
    );
};

function TitleResult() {
    const { Title } = Typography;

    return (
        <Row>
            <Col span={24}>
                {/* <Title level={2}>¡Ya casi terminamos!</Title> */}
                {/* <Title level={3}>{'Confirma tu número telefónico'}</Title> */}
                <Title level={2}>¡ Bienvenido !</Title>
                <Title level={3}>
                    {'Tu registro ha sido confirmado exitosamente.'}
                </Title>
            </Col>
        </Row>
    );
}

function SubTitleResult({ phone }) {
    const { Paragraph } = Typography;

    return (
        <Row>
            <Col span={24}>
                {/* <Paragraph type="secondary">
                    Necesitamos validar tu número de telefónico:
                </Paragraph>
                <Paragraph>
                    <Text strong>{phone}</Text>
                </Paragraph>
                <Paragraph type="secondary">
                    {' '}
                    Ingresa por favor el codigo que recibiste a tu teléfono para
                    terminar el registro.
                </Paragraph> */}
                <Paragraph type="secondary">
                    Ingresa para poder comenzar a disfrutar.
                </Paragraph>
            </Col>
        </Row>
    );
}

export default withRouter(ConfirmEmail);
