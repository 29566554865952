import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import AdminHome from '../pages/Admin/Perfil';
import ClientHome from '../pages/Client';
import RunnerHome from '../pages/Runner';

import './LayoutBasic.scss';

export default function LayoutBasic(props) {
    const { routes } = props;
    const { Content } = Layout;

    const { user, isLoading } = useAuth();

    console.log(user);

    if (user) {
        const rolUser =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];

        if (!isLoading && rolUser === 'Admin') {
            return (
                <>
                    <Route path="/admin" component={AdminHome} />
                    <Redirect to="/admin" />
                </>
            );
        } else if (!isLoading && rolUser === 'Operator') {
            return (
                <>
                    <Route path="/admin" component={AdminHome} />
                    <Redirect to="/admin" />
                </>
            );
        } else if (!isLoading && rolUser === 'Client') {
            return (
                <>
                    <Route path="/" component={ClientHome} />
                    <Redirect to="/" />
                </>
            );
        } else if (user && !isLoading && rolUser === 'Runner') {
            return (
                <>
                    <Route path="/runner" component={RunnerHome} />
                    <Redirect to="/runner" />
                </>
            );
        }
    }

    return (
        <Layout>
            <Content>
                <LoadRoutes routes={routes} />
            </Content>
        </Layout>
    );
}

function LoadRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
}
