import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Select,
    Upload,
    Button,
    Image,
    Spin,
    message,
    notification,
    Row,
    Col,
    Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { editGroupMenu, addImageGroupMenu } from '../../../../api/menu';
import { resizeFile } from '../../../../utils/formValidation';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditGroup = (props) => {
    const {
        element,
        imageGroup,
        setIsModalVisible,
        menuAdmin,
        setMenuAdmin,
    } = props;
    const [groupForm, setGroupForm] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        setGroupForm([
            {
                name: ['Name'],
                value: element.name,
            },
            {
                name: ['Priority'],
                value: element.priority,
            },
        ]);

        return () => {
            form.resetFields();
            setImage('');
            setGroupForm([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const { Option } = Select;

    const [image, setImage] = useState('');

    const [filesList, setFileList] = useState({});

    const [loadingForm, setLoadingForm] = useState(false);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.warning('Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1000 < 250;
        if (!isLt2M) {
            message.warning(
                'La imegen debe ser menos de 250 kb para que no pierda calidad'
            );
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            fileList = fileList.slice(-1);
            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) => setImage(imageUrl));
        } else {
            setImage('');
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    function Cancel() {
        // form.resetFields();
        setImage('');
        setIsModalVisible(false);
    }

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;

        formData['CompanyId'] = companyId;
        formData['GroupId'] = element.groupId;

        if (values.upload !== undefined) {
            if (values.upload.length > 0) {
                let image = values.upload[0];

                const isJpgOrPng =
                    image.type === 'image/jpeg' ||
                    image.type === 'image/png' ||
                    image.type === 'image/jpg';

                if (!isJpgOrPng) {
                    notification['error']({
                        message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
                    });
                    setLoadingForm(false);
                    return false;
                }

                const isLt2M = image.size / 1000 < 250;
                let imageResize = '';
                if (!isLt2M) {
                    notification['warning']({
                        message:
                            'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
                        placement: 'bottomRight',
                        duration: 3,
                    });
                    imageResize = await resizeFile(image.originFileObj);
                }

                let posData64;
                // let imageBase64;

                // let posType = image.type.indexOf("/")+1;
                if (isLt2M) {
                    posData64 = image.thumbUrl.indexOf(',') + 1;
                    // imageBase64 = image.thumbUrl.substr(posData64);
                } else {
                    posData64 = imageResize.indexOf(',') + 1;
                    // imageBase64 = imageResize.substr(posData64);
                }

                // let posData64 = image.thumbUrl.indexOf(",")+1;
                let posType = image.type.indexOf('/') + 1;

                let formDataImage = {
                    CompanyId: companyId,
                    ImageData: isLt2M
                        ? image.thumbUrl.substr(posData64)
                        : imageResize.substr(posData64),
                    Extension: image.type.substr(posType),
                    MenuGroupId: element.groupId,
                };

                const resultImage = await addImageGroupMenu(
                    token,
                    formDataImage
                );

                if (resultImage.statusCode === 200) {
                    const found = menuAdmin.findIndex(
                        (obj) => obj.groupId === element.groupId
                    );

                    let imageJson = {
                        companyId: element.companyId,
                        extension: image.type.substr(posType),
                        fileLocation: null,
                        fileName: resultImage.result.fileName,
                        fileType: 'Image',
                        imageData: isLt2M
                            ? image.thumbUrl.substr(posData64)
                            : imageResize.substr(posData64),
                        imageId: resultImage.result.imageId,
                    };
                    menuAdmin[found].groupImage = imageJson;
                } else {
                    notification['error']({
                        message: 'Ocurrio un problema al modificar la imagen',
                    });
                }
            }
        }

        const result = await editGroupMenu(token, formData);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            setLoadingForm(false);

            notification['success']({
                message: 'Grupo modificado exitosamente',
            });

            const found = menuAdmin.findIndex(
                (obj) => obj.groupId === element.groupId
            );
            menuAdmin[found].name = result.result.name;
            menuAdmin[found].priority = result.result.priority;

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menuAdmin.sort(compare);

            setMenuAdmin(menuAdmin);

            setIsModalVisible(false);
        } else {
            setLoadingForm(false);
            let message =
                'Ocurrio un problema al crear un nuevo grupo, Intentelo otra vez';
            if (result.statusCode === 404) {
                message = result.description;
            }
            notification['error']({
                message: message,
            });
        }
    };

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="groupName"
                onFinish={onFinish}
                form={form}
                fields={groupForm}
                onFieldsChange={(changedFields, allFields) => {
                    setGroupForm(allFields);
                }}
            >
                <Form.Item
                    label="Nombre del grupo"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre del grupo" />
                </Form.Item>

                <div className="group-form__container-image">
                    <Image
                        width={200}
                        src={image !== '' ? image : imageGroup}
                    />
                </div>

                <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra="Imagen adjunta"
                >
                    <Upload
                        name="logo"
                        beforeUpload={beforeUpload}
                        listType="picture"
                        multiple={false}
                        onChange={handleChange}
                        fileList={filesList}
                    >
                        <Button icon={<UploadOutlined />}>
                            Click para cargar la imagen
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="Priority"
                    label="Prioridad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione una prioridad" allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default EditGroup;
