import React, { useState, useEffect } from 'react';
import {
    Spin,
    Form,
    Input,
    Select,
    Row,
    Col,
    Divider,
    Button,
    notification,
} from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { editCategoryMenu } from '../../../../api/menu';

import './EditCategoryForm.scss';

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 14,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditCategoryForm = (props) => {
    const { setIsModalVisible, element, menuAdmin, setMenuAdmin } = props;
    const [categoryForm, setCategoryForm] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        setCategoryForm([
            {
                name: ['Name'],
                value: element.name,
            },
            {
                name: ['Priority'],
                value: element.priority,
            },
        ]);

        return () => {
            form.resetFields();
            setCategoryForm([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const { Option } = Select;

    const [loadingForm, setLoadingForm] = useState(false);

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;

        formData['CompanyId'] = companyId;
        formData['GroupId'] = element.groupId;
        formData['CategoryId'] = element.categoryId;

        const result = await editCategoryMenu(token, formData);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message: 'Ocurrió un error, inténtelo de nuevo .',
            });
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Categoría modificada exitosamente',
            });

            const found = menuAdmin.findIndex(
                (obj) => obj.groupId === element.groupId
            );
            const foundCat = menuAdmin[found].categories.findIndex(
                (cat) => cat.categoryId === element.categoryId
            );

            menuAdmin[found].categories[foundCat].name = result.result.name;
            menuAdmin[found].categories[foundCat].priority =
                result.result.priority;

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menuAdmin[found].categories.sort(compare);

            setMenuAdmin(menuAdmin);

            setLoadingForm(false);
            setIsModalVisible(false);
        } else {
            let messageError =
                'Ocurrió un problema al editar la categoría , Intentelo otra vez';

            if (result.statusCode === 404) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    const Cancel = () => {
        // form.resetFields();
        setIsModalVisible(false);
    };

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="category"
                form={form}
                onFinish={onFinish}
                fields={categoryForm}
                onFieldsChange={(changedFields, allFields) => {
                    setCategoryForm(allFields);
                }}
            >
                <Form.Item
                    label="Nombre de la categoría"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre de la categoría" />
                </Form.Item>

                <Form.Item
                    name="Priority"
                    label="Prioridad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione una prioridad" allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default EditCategoryForm;
