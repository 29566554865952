import React, { useEffect, useState } from 'react';
import {
    Form,
    Spin,
    Input,
    Checkbox,
    Tooltip,
    Button,
    Col,
    Row,
    Select,
    DatePicker,
    notification,
} from 'antd';
import {
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    CarOutlined,
    CreditCardOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import { editAdminRunner } from '../../../../api/runner';
import { userLogOut } from '../../../../utils/general';
import { getAdminRunner } from '../../../../api/runner';
import moment from 'moment';

import './EditRunnerForm.scss';

const EditRunnerForm = ({
    runner,
    setIsVisibleModal,
    setGroupAdmin,
    groupAdmin,
    runners,
}) => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingRunner, setLoadingRunner] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        setLoadingRunner(true);
        getAdminRunner(token, runner.id).then((runner) => {
            console.log(runner, 'runner');
            if (runner === undefined) {
                notification['error']({
                    message:
                        'Ocurrio un error al cargar el runner intentelo de nuevo mas tarde..',
                });
                setIsVisibleModal(false);
                return;
            }
            if (runner.statusCode === 404) {
                notification['error']({
                    message:
                        'Ocurrio un error al cargar el runner intentelo de nuevo mas tarde..',
                });
                setIsVisibleModal(false);
                return;
            }
            setUserData([
                {
                    name: ['Name'],
                    value:
                        runner.result.name !== null
                            ? runner.result.name
                            : runner.name,
                },
                {
                    name: ['LastName'],
                    value:
                        runner.result.lastName !== null
                            ? runner.result.lastName
                            : runner.lastName,
                },
                {
                    name: ['email'],
                    value:
                        runner.result.email !== null
                            ? runner.result.email
                            : runner.email,
                },
                {
                    name: ['phoneNumber'],
                    value:
                        runner.result.phoneNumber !== null
                            ? runner.result.phoneNumber
                            : runner.phoneNumber,
                },
                {
                    name: ['Active'],
                    value:
                        runner.result.active !== null
                            ? runner.result.active
                            : runner.active,
                },
                {
                    name: ['authorized'],
                    value:
                        runner.result.authorized !== null
                            ? runner.result.authorized
                            : false,
                },
                {
                    name: ['VehicleType'],
                    value:
                        runner.result.vehicle.type !== null
                            ? runner.result.vehicle.type
                            : runner.vehicle.type,
                },
                {
                    name: ['Plate'],
                    value:
                        runner.result.vehicle.plate !== null
                            ? runner.result.vehicle.plate
                            : runner.vehicle.plate,
                },
                {
                    name: ['birthDay'],
                    value:
                        moment.unix(runner.result.birthDate) !== null
                            ? moment.unix(runner.result.birthDate)
                            : moment.unix(runner.birthDate),
                },
            ]);
            setLoadingRunner(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, runner]);

    const updateUser = async (values) => {
        setLoading(true);
        const token = getAccessTokenApi();
        if (token == null) {
            userLogOut();
            return;
        }

        let userUpdate = values;
        delete userUpdate.email;
        userUpdate['phoneNumber'] = userUpdate['phoneNumber'].toString();
        userUpdate['id'] = runner.id;
        userUpdate['birthDate'] = values.birthDay.unix();
        userUpdate['Vehicle'] = {
            type: values.VehicleType,
            plate: values.Plate,
            id: runner.vehicle.id,
        };
        const result = await editAdminRunner(token, userUpdate);
        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409 || result.statusCode === 400) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                //recreo correctamenta
                const runnerJSON = {
                    name: result.result.name,
                    lastName: result.result.lastName,
                    email: result.result.email,
                    phoneNumber: result.result.phoneNumber,
                    birthDate: moment.unix(result.result.birthDate),
                    vehicle: {
                        type: result.result.vehicle.type,
                        plate: result.result.plate,
                        id: result.result.vehicle.id,
                    },
                    id: runner.id,
                    isOnline: result.result.isOnline,
                    active: result.result.active,
                    authorized: result.result.authorized,
                };
                const onEdited = runners.indexOf(runner);
                if (onEdited !== -1) runners[onEdited] = runnerJSON;

                setIsVisibleModal(false);
                setGroupAdmin(groupAdmin);
                notification['success']({
                    message: 'Runner actualizado exitosamente',
                });
            }
        }

        setLoading(false);
    };

    return (
        <div className="edit-user-form">
            <Spin spinning={loadingRunner}>
                <EditForm
                    userData={userData}
                    setUserData={setUserData}
                    updateUser={updateUser}
                    loading={loading}
                />
            </Spin>
        </div>
    );
};

export default EditRunnerForm;

function EditForm(props) {
    const { userData, setUserData, updateUser, loading } = props;
    const { Option } = Select;
    const [disableButton, setDisableButton] = useState(true);
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

    function disabledDate(current) {
        return current > moment().endOf('day');
    }

    return (
        <Spin tip="Guardando..." spinning={loading}>
            <Form
                className="form-edit"
                onFinish={updateUser}
                fields={userData}
                scrollToFirstError={true}
                onFieldsChange={(changedFields, allFields) => {
                    setUserData(allFields);
                    if (disableButton !== false) {
                        setDisableButton(false);
                    }
                }}
            >
                <Row gutter={24}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Correo Electrónico"
                            name="email"
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Email"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name="Name"
                            label="Nombre del runner"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Nombre"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name="LastName"
                            label="Apellido del runner"
                            hasFeedback
                            rules={[
                                {
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Apellido"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="birthDay"
                            label={'Fecha de nacimiento'}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú fecha de nacimiento',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        const years = moment().diff(
                                            value,
                                            'years',
                                            true
                                        );
                                        if (years >= 18) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                'Debe de tener 18 años o más'
                                            );
                                        }
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                                className={'picker-widt'}
                                initialValues={moment(
                                    '01/01/2002',
                                    dateFormatList[0]
                                )}
                                format={dateFormatList}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phoneNumber"
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder="Teléfono"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Vehículo"
                            name="VehicleType"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccione un vehículo',
                                },
                            ]}
                        >
                            <Select
                                disabled
                                placeholder="Selecciona"
                                name="type"
                                prefix={<CarOutlined />}
                            >
                                <Option value={0}>Automóvil</Option>
                                <Option value={1}>Motocicleta</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="Plate"
                            label="Placas"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese las placas',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<CreditCardOutlined />}
                                placeholder="ABCD123"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="Active"
                            valuePropName="checked"
                            label="Activo"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="authorized"
                            valuePropName="checked"
                            label="Autorizado"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button block type="primary" htmlType="submit">
                        Actualizar Runner
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
