import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Divider, Typography } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import PerfilForm from '../../../components/Client/PerfilForm';
import { RightOutlined } from '@ant-design/icons';
import './Perfil.scss';

const Perfil = () => {
    const { Content } = Layout;
    const { Text } = Typography;

    return (
        <Layout className="perfil">
            <Content>
                <HeaderTop title={'Mi Perfil'} backIcon={true} backUrl={'/'} />

                <div className="perfil__container">
                    <Divider>Información Personal</Divider>
                    <PerfilForm />

                    <Divider> Otras Opciones </Divider>
                    <Divider />
                    <Link to="/password">
                        <Text
                            style={{ fontSize: 18 }}
                            level={4}
                            type="secondary"
                        >
                            Cambio de Contraseña{' '}
                            <RightOutlined className="perfil__icon" />
                        </Text>
                    </Link>
                    <Divider />
                </div>
            </Content>
        </Layout>
    );
};

export default Perfil;
