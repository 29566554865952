import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import useUser from '../hooks/useUser';
import Login from '../pages/Login';
import AdminHome from '../pages/Admin/Perfil';
import RunnerHome from '../pages/Runner';

import './LayoutClient.scss';

function LayoutClient(props) {
    const { routes } = props;
    const { Content } = Layout;
    const { user, isLoading } = useAuth();
    const { person } = useUser();
    const { userType, companyId } = person;
    let rolUser = '';

    if (user) {
        rolUser =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
    }

    if (!user && !isLoading && userType === 0 && companyId === null) {
        return (
            <>
                <Route path="/login" component={Login} />
                <Redirect to="/login" />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Admin') {
        return (
            <>
                <Route path="/admin/orden" component={AdminHome} />
                <Redirect to="/admin/orden" />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Operator') {
        return (
            <>
                <Route path="/admin" component={AdminHome} />
                <Redirect to="/admin" />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Client') {
        return (
            <Layout>
                <Content className="content-client">
                    <LoadRoutes routes={routes} />
                </Content>
            </Layout>
        );
    }

    if (user && !isLoading && rolUser === 'Runner') {
        return (
            <>
                <Route path="/runner/orders" component={RunnerHome} />
                <Redirect to="/runner/orders" />
            </>
        );
    }

    return null;

    // return (
    //     <>
    //         {console.log('problema')}
    //         <Route path="/login" component={Login} />
    //         <Redirect to="/login" />
    //     </>
    // );
}

function LoadRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
}

export default withRouter(LayoutClient);
