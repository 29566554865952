import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Checkbox,
  Divider,
  Layout,
  Form,
  Input,
  div,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EditOptionForm from "../EditOptionForm/EditOptionForm";
import Modal from "../../../../General/Modal";

const EditIngredientForm = (props) => {
  const { showOptions } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("Titulo");
  const [contextModal, setContextModal] = useState(null);
  const [showMOptions, setshowMOptions] = useState(showOptions);

  useEffect(() => {
    setshowMOptions(showOptions);
  }, [showOptions]);
  const addMOptions = (e) => {
    setshowMOptions(e.target.checked);
  };

  const showModal = (title, element, type, groupId) => {
    console.log(title, type);
    setIsModalVisible(true);
    setTitleModal(title);

    switch (type) {
      case "EditOption":
        setContextModal(<EditOptionForm></EditOptionForm>);
        break;

      default:
        break;
    }
  };

  return (
    <Form>
      <div>
        <Form.Item
          label="Sección"
          name="titleIngredient"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un título",
            },
          ]}
        >
          <Input placeholder="Elige tu salsa" />
        </Form.Item>
        <Row class="margin-check">
          <Checkbox>Sección Obligatoria</Checkbox>
        </Row>
        <Row>
          <Checkbox onChange={(e) => addMOptions(e)}>
            Múltiples Opciones
          </Checkbox>
        </Row>
        {showMOptions && (
          <Form.Item
            label="Número de opciones"
            name="titleIngredient"
            rules={[
              {
                required: true,
                message: "Por favor ingresa un número",
              },
            ]}
          >
            <Input placeholder="2" />
          </Form.Item>
        )}
        <br />
        <Divider>Editar Opciones</Divider>
        <IngredientTable showModal={showModal} />
      </div>
      <Modal
        className={["margin-form"]}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        title={titleModal}
        footer={false}
        // destroyOnClose
      >
        {contextModal}
      </Modal>
    </Form>
  );
};

export default EditIngredientForm;

function IngredientTable(props) {
  const { showDeleteConfirm, showModal } = props;
  const titleDelete = `la opción:`;
  const titleModal = "Editar Opción";
  let type = "EditOption";
  let actionEdit = "EditOption";

  return (
    <div className="border-layout-ing ">
      <Row className="space-content align-items-center">
        <Col className="height-items" xs={4} md={8} lg={6}>
          <h3>Salsa</h3>
        </Col>
        <Row xs={12} className="space-content">
          <Button
            className="btn-icons"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => showModal(titleModal, type, actionEdit)}
          />
          <Button
            className="btn-icons margin-button-ing"
            type="primary"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(titleDelete)}
          />
        </Row>
      </Row>
    </div>
  );
}
