import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Checkbox,
  Divider,
  Form,
  Spin,
  notification,
  Input,
  Modal as ModalAntd,
  InputNumber,
  Switch,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import EditOptionForm from "../EditOptionForm/EditOptionForm";
import Modal from "../../../../General/Modal";
import { getAccessTokenApi } from "../../../../../api/auth";
import { getCompanyIdApi } from "../../../../../api/company";
import {
  editItemSection,
  deleteSectionOptions,
  createSectionOption,
} from "../../../../../api/itemSections";

import "./EditSectionForm.scss";
import { formatMoney } from "../../../../../utils/general";

const EditIngredientForm = (props) => {
  const { element, setIsModalVisible, itemSection, setItemSection } = props;
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [titleModal, setTitleModal] = useState("Titulo");
  const [contextModal, setContextModal] = useState(null);
  const [multipleOptions, setMultipleOptions] = useState();
  const [available, setAvailable] = useState();
  const [optionName, setOptionName] = useState("");
  const [availableOption, setAvailableOption] = useState(false);
  const [hasExtraPrice, setHasExtraPrice] = useState(false);
  const [priceOption, setPriceOption] = useState();
  const [name, setName] = useState("");
  const [maxOptions, setMaxOptions] = useState(null);
  const [optionForm, setOptionForm] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);

  const [elementUpdate, setElementUpdate] = useState({});
  useEffect(() => {
    setElementUpdate(element);
  }, [element]);
  const token = getAccessTokenApi();
  const [form] = Form.useForm();
  const { confirm } = ModalAntd;

  const showDeleteConfirm = (option, options) => {
    const id = option.sectionOptionId;
    const filtradas = options.filter((ele) => ele.available === true);

    if (element.multipleOptions === true && maxOptions >= options.length) {
      notification["error"]({
        message: `El número de opciones no puede ser menor a la cantidad máxima de opciones`,
      });
    } else if (options.length === 1) {
      notification["error"]({
        message: `No puedes tener menos de una opción`,
      });
    } else if (
      option.available === true &&
      available === true &&
      filtradas.length === 1
    ) {
      setLoadingForm(false);
      notification["error"]({
        message:
          "Si la sección esta disponible tiene que haber mínimo una opción disponible.",
      });
    } else {
      confirm({
        title: `Estas seguro que deseas borrar la opción ${option.name}`,
        icon: <ExclamationCircleOutlined />,
        content: "No podras recuperarlo despues...",
        onOk() {
          deleteSectionOptions(token, id)
            .then((res) => {
              if (res.status === 204) {
                notification["success"]({
                  message: `Opción eliminada exitosamente`,
                });
                const filtradas = options.filter(
                  (ele) => ele.sectionOptionId !== option.sectionOptionId
                );
                // console.log(filtradas);

                const onEditedOps = itemSection.findIndex(
                  (obj) => obj.itemSectionId === option.itemSectionId
                );

                // console.log('itemSection Modi', itemSection);
                // console.log('onEditedOps Modi', onEditedOps);

                if (onEditedOps !== -1) {
                  itemSection[onEditedOps] = {
                    ...elementUpdate,
                    sectionOptions: filtradas,
                  };
                }

                setItemSection(itemSection);
                setElementUpdate({
                  ...elementUpdate,
                  sectionOptions: filtradas,
                });
              }
              if (res.status === 400) {
                notification["error"]({
                  message: `Ocurrio un porblema, ${option.name} no se pudo eliminar`,
                });

                return false;
              }
              if (res.status === 404) {
                notification["error"]({
                  message: `Ocurrio un porblema, ${option.name} no se pudo eliminar`,
                });

                return false;
              }
            })
            .catch((err) => {
              return false;
            });
        },
        onCancel() {},
      });
    }
  };

  const showModal = (title, option, arrayOptions) => {
    setIsModalVisibleForm(true);
    setTitleModal(title);
    setContextModal(
      <EditOptionForm
        option={option}
        arrayOptions={arrayOptions}
        setIsModalVisibleForm={setIsModalVisibleForm}
      ></EditOptionForm>
    );
  };

  useEffect(() => {
    setOptionForm([
      {
        name: ["itemSectionId"],
        value: element.itemSectionId,
      },
      {
        name: ["name"],
        value: element.name,
      },
      {
        name: ["obligatory"],
        value: element.obligatory,
      },
      {
        name: ["multipleOptions"],
        value: element.multipleOptions,
      },
      {
        name: ["maxOptions"],
        value: element.maxOptions,
      },
      {
        name: ["available"],
        value: element.available,
      },
      {
        name: ["sectionOptions"],
        value: element.sectionOptions,
      },
    ]);
    if (element.multipleOptions === true) {
      setMultipleOptions(true);
    } else if (element.multipleOptions === false) {
      setMultipleOptions(false);
    }
    setMaxOptions(element.maxOptions);
    setAvailable(element.available);
    return () => {
      setOptionForm([]);
    };
  }, [element]);

  const onAddOptionClicked = async () => {
    const newArrayOption = {
      itemSectionId: elementUpdate.itemSectionId,
      companyId: elementUpdate.companyId,
      available: availableOption,
      maxIngredient: 1,
      price: priceOption,
      hasExtraPrice: hasExtraPrice,
      name: optionName,
      priority: 1,
    };

    let filtradas = [];
    if (elementUpdate.sectionOptions !== null) {
      filtradas = elementUpdate.sectionOptions.filter(
        (ele) => ele.name === optionName
      );
    }

    if (hasExtraPrice && priceOption === null) {
      notification["error"]({
        message: "El precio no puede estar vacio.",
      });
      return false;
    }

    if (filtradas.length >= 1) {
      notification["error"]({
        message: "Ya existe una opción con ese nombre",
      });
    } else {
      const result = await createSectionOption(token, newArrayOption);
      if (result.statusCode === 200) {
        setLoadingForm(false);
        notification["success"]({
          message: result.description,
        });
        const newArrayOption = {
          available: result.result.available,
          companyId: result.result.companyId,
          hasExtraPrice: result.result.hasExtraPrice,
          itemSectionId: result.result.itemSectionId,
          maxIngredient: result.result.maxIngredient,
          price: result.result.price,
          name: result.result.name,
          sectionOptionId: result.result.sectionOptionId,
        };
        // setElementUpdate([...elementUpdate, newArrayOption]);
        setElementUpdate({
          ...elementUpdate,
          sectionOptions: [...elementUpdate.sectionOptions, newArrayOption],
        });

        const onEditedSection = itemSection.findIndex(
          (obj) => obj.itemSectionId === result.result.itemSectionId
        );

        if (onEditedSection !== -1) {
          itemSection[onEditedSection] = {
            ...elementUpdate,
            sectionOptions: [...elementUpdate.sectionOptions, newArrayOption],
          };

          // console.log([
          //     ...elementUpdate.sectionOptions,
          //     newArrayOption,
          // ]);

          // console.log('itemSection Modi', itemSection);

          setItemSection(itemSection);
          clearAddOptions();
        }
      }
      if (result.statusCode === 400) {
        notification["error"]({
          message: result.description,
        });
        setLoadingForm(false);
      }
      // else {
      //     setLoadingForm(false);
      //     let messageError = result.description;
      //     notification['error']({
      //         message: messageError,
      //     });
      // }
    }
  };

  const clearAddOptions = () => {
    setAvailableOption(false);
    setOptionName("");
    setHasExtraPrice(false);
    setPriceOption(null);
  };

  const onFinish = async (values) => {
    setLoadingForm(true);
    let formData = values;
    let id = elementUpdate.itemSectionId;
    formData["itemSectionId"] = id;

    if (!formData["multipleOptions"]) {
      formData["maxOptions"] = 1;
    }
    const filtradas = elementUpdate.sectionOptions.filter(
      (ele) => ele.available === true
    );

    if (elementUpdate.sectionOptions.length === 0) {
      setLoadingForm(false);

      notification["error"]({
        message: "Tiene que haber mínimo una opción añadida.",
      });
    } else if (formData.maxOptions > elementUpdate.sectionOptions.length) {
      setLoadingForm(false);
      notification["error"]({
        message:
          "El número de opciones tiene que ser mayor a la cantidad máxima.",
      });
    } else if (formData.available === true && filtradas.length === 0) {
      setLoadingForm(false);
      notification["error"]({
        message: "Tiene que haber mínimo una opción disponible.",
      });
    } else {
      const result = await editItemSection(token, formData, id);
      if (result) {
        if (result.statusCode === 200) {
          setLoadingForm(false);
          notification["success"]({
            message: result.description,
          });
          const companyId = getCompanyIdApi();

          const sectionJSON = {
            available: result.result.available,
            itemSectionId: result.result.itemSectionId,
            maxOptions: result.result.maxOptions,
            multipleOptions: result.result.multipleOptions,
            name: result.result.name,
            obligatory: result.result.obligatory,
            sectionOptions: elementUpdate.sectionOptions,
            companyId: companyId,
          };

          const onEdited = itemSection.findIndex(
            (obj) => obj.itemSectionId === elementUpdate.itemSectionId
          );

          if (onEdited !== -1) {
            itemSection[onEdited] = sectionJSON;
            setItemSection(itemSection);
          }
          setIsModalVisible(false);
        }
        if (result.statusCode === 400) {
          setLoadingForm(false);
          notification["error"]({
            message: result.description,
          });
        }
        if (result.statusCode === undefined) {
          setLoadingForm(false);
          let messageError =
            "Ocurrio un problema al editar la sección, Intentelo mas tarde.";

          if (result.statusCode === 404) {
            setLoadingForm(false);
            messageError = result.description;
          }
          notification["error"]({
            message: messageError,
          });
          return true;
        }
        setLoadingForm(false);
        clearAddOptions();
      }
    }
  };

  return (
    <Spin tip="Guardando..." spinning={loadingForm} size="large">
      <Form
        form={form}
        onFinish={onFinish}
        fields={optionForm}
        onFieldsChange={(changedFields, allFields) => {
          setOptionForm(allFields);
        }}
      >
        <div>
          <Form.Item
            label="Sección"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un nombre",
              },
            ]}
          >
            <Input
              placeholder="Escribe el nombre de la sección"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="available"
            label="Disponible:"
            value={available}
          >
            <Switch
              checked={available}
              onChange={(check) => setAvailable(check)}
            />
          </Form.Item>
          <Form.Item
            name="obligatory"
            valuePropName="checked"
            label="Sección Obligatoria"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="multipleOptions"
            valuePropName="checked"
            label="Múltiples Opciones"
            value={multipleOptions}
          >
            <Checkbox onChange={(e) => setMultipleOptions(e.target.checked)} />
          </Form.Item>
          {multipleOptions && (
            <Form.Item
              label="Máximo de opciones"
              name="maxOptions"
              rules={[
                {
                  required: false,
                  message: "Por favor ingresa un número",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                parser={(value) => value.replace(/\$\s?|(,*)\./g, "")}
                placeholder="2"
                value={maxOptions}
                onChange={(value) => setMaxOptions(value)}
              />
            </Form.Item>
          )}
          <br />
          <Divider>Agregar Opciones</Divider>
          <Row>
            <Col span={20}>
              <Form.Item label="Opción">
                <Input
                  placeholder="Escribe el nombre de la opción"
                  value={optionName}
                  onChange={(e) => setOptionName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4} className="add-ingredient">
              <Button
                disabled={optionName === ""}
                className={["btn-icons", "btn-add", "add-ingredient-button"]}
                type="primary"
                size={"small"}
                shape="circle"
                icon={<PlusOutlined />}
                onClick={onAddOptionClicked}
              />
            </Col>
          </Row>
          <Form.Item initialValue={false} label="Disponible:">
            <Switch
              checked={availableOption}
              onChange={(check) => setAvailableOption(check)}
            />
          </Form.Item>
          <Row className="margin-check">
            <Form.Item
              label="Costo extra"
              // name="hasExtraPrice"
              // valuePropName="checked"
              // value={hasExtraPrice}
              initialValue={false}
            >
              <Checkbox
                checked={hasExtraPrice}
                onChange={(e) => setHasExtraPrice(e.target.checked)}
              />
            </Form.Item>
          </Row>

          {hasExtraPrice && (
            <Form.Item
              label="Precio"
              // name="priceOption"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese el precio",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="Precio"
                value={priceOption}
                onChange={(value) => setPriceOption(value)}
              />
            </Form.Item>
          )}
          <Divider>Editar Opciones</Divider>
          <IngredientTable
            showModal={showModal}
            // options={element.sectionOptions}
            options={elementUpdate.sectionOptions}
            showDeleteConfirm={showDeleteConfirm}
          />
        </div>
        <Row className="display-button-right">
          <Form.Item>
            <Button className="margin-check" htmlType="submit" type="primary">
              Guardar
            </Button>
          </Form.Item>
        </Row>
        <Modal
          className={["margin-form"]}
          isVisible={isModalVisibleForm}
          setIsVisible={setIsModalVisibleForm}
          title={titleModal}
          footer={false}
          // destroyOnClose
        >
          {contextModal}
        </Modal>
      </Form>
    </Spin>
  );
};

export default EditIngredientForm;

function IngredientTable(props) {
  const { showDeleteConfirm, showModal, options } = props;
  const titleModal = "Editar Opción";
  return (
    <>
      {options &&
        options.length >= 1 &&
        options.map((option, index) => {
          return (
            <div key={index} className="border-layout-ing ">
              <Row className="space-content align-items-center">
                <Col className="height-items" xs={12} md={20} lg={20}>
                  <Row>
                    <h3>{option.name}</h3>
                    {option.price !== null && (
                      <h3 className="margin-left">
                        {" "}
                        ${formatMoney(option.price)}
                      </h3>
                    )}

                    {option.available && (
                      <p className="margin-left">
                        | Disponible{" "}
                        <CheckCircleTwoTone
                          twoToneColor="#52c41a"
                          style={{ fontSize: "22px", marginLeft: "10px" }}
                        />
                      </p>
                    )}
                  </Row>
                </Col>
                <Row xs={12} className="space-content">
                  <Button
                    className="btn-icons"
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => showModal(titleModal, option, options)}
                  />
                  <Button
                    className="btn-icons margin-button-ing"
                    type="primary"
                    shape="circle"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => showDeleteConfirm(option, options)}
                  />
                </Row>
              </Row>
            </div>
          );
        })}
      {options && options.length === 0 && <p>NO HAY OPCIONES</p>}
    </>
  );
}
