import React, { useRef, useEffect, useState } from 'react';
import {
    Table,
    Statistic,
    Row,
    Col,
    Button,
    Typography,
    Spin,
    DatePicker,
    Space,
    Tag,
    notification,
    Select,
    Divider,
} from 'antd';
import {
    FilePdfOutlined,
    DollarOutlined,
    CreditCardOutlined,
    CarryOutOutlined,
    FileSearchOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getRecordsOrdersRunnerApi,
    getRunnerGroups,
    getRunnerGroup,
    getOrdersCashCut,
    getLastDateCashCut,
    postCreateCashCut,
    getLastCashCut,
} from '../../../api/runner';
import { formatMoney } from '../../../utils/general';
import './HomeDeliveries.scss';
import Modal from '../../../components/General/Modal';

const HomeDeliveries = () => {
    const [loading, setLoading] = useState(false);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [ordersRunners, setOrdersRunners] = useState([]);
    const [runnerGroups, setRunnerGroups] = useState([]);
    const [runnerListByGroup, setRunnerListByGroup] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [ordersPdf, setOrdersPdf] = useState([]);
    const [runnerName, setRunnerName] = useState(null);
    const [idRunnerGroup, setIdRunnerGroup] = useState(null);
    const [dateRecord, setDateRecod] = useState({
        startDate: moment().subtract(7, 'days').unix(),
        endDate: moment().unix(),
    });
    const { RangePicker } = DatePicker;
    const isMounted = useRef(true);
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const { Paragraph, Text } = Typography;
    const startDate = moment.unix(dateRecord.startDate);
    const endDate = moment.unix(dateRecord.endDate);
    const [staticData, setStaticData] = useState({
        Profits: 0,
        TotalSells: 0,
        TotalEfectivo: 0,
        TotalTarjeta: 0,
        DeudaRunner: 0,
        DeudaCompany: 0,
    });
    const token = getAccessTokenApi();

    useEffect(() => {
        getData();
        getGroups();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRecord, idRunnerGroup, runnerName]);

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
        createValueTable(extra.currentDataSource);
    };

    function onChangeDate(date, dateString) {
        if (date) {
            setDateRecod({
                startDate: date[0].unix(),
                endDate: date[1].unix(),
            });
        }
    }
    function onChangeSelectRunner(runnerName) {
        setRunnerName(runnerName);
    }
    const onChangeRunnerGroup = async (idGroup) => {
        if (idGroup) {
            setIdRunnerGroup(idGroup);
            const result = await getRunnerGroup(token, idGroup);
            setRunnerListByGroup(result.result.companyRunners);
        }
    };
    // &runnerId=${1}&runnerName=${2}
    const getData = async () => {
        setLoading(true);
        let filters = '';
        if (idRunnerGroup !== null) {
            filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}&groupId=${idRunnerGroup}`;
        }
        if (runnerName !== null && runnerName !== undefined) {
            filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}&groupId=${idRunnerGroup}&runnerEmail=${runnerName}`;
        } else if (idRunnerGroup === null && runnerName === null) {
            filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}`;
        }

        getRecordsOrdersRunnerApi(token, filters).then((response) => {
            if (response?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });
                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1000);
                return;
            } else if (response?.statusCode === 200) {
                response.result.forEach((element, index) => {
                    element['key'] = index;
                });
                setOrdersRunners(response.result);
                createValueTable(response.result);
            }
        });
    };
    const getGroups = async () => {
        setLoading(true);
        getRunnerGroups(token).then((response) => {
            if (response?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });
                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1000);
                return;
            } else if (response?.statusCode === 200) {
                setRunnerGroups(response.result);
            }
        });
    };
    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
        setDateRecod({
            startDate: moment().subtract(7, 'days').unix(),
            endDate: moment().unix(),
        });
        setIdRunnerGroup(null);
        setRunnerName(null);
    };

    const columns = [
        {
            title: '# Orden',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            sorter: (a, b) => a.orderId - b.orderId,
            sortOrder: sortedInfo.columnKey === 'orderNum' && sortedInfo.order,
            ellipsis: true,
            render: (orderTotal) => <p># {orderTotal}</p>,
        },
        {
            title: 'Fecha',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            width: 200,
            sorter: (a, b) => a.deliveryDate - b.deliveryDate,
            sortOrder:
                sortedInfo.columnKey === 'deliveryDate' && sortedInfo.order,
            ellipsis: true,
            render: (deliveryDate) => (
                <>{moment.unix(deliveryDate).format('DD/MM/YYYY, h:mm a')}</>
            ),
        },
        {
            title: 'Runner',
            dataIndex: 'runnerEmail',
            key: 'runnerEmail',
            width: 200,
            sorter: (a, b) => a.runnerEmail - b.runnerEmail,
            sortOrder:
                sortedInfo.columnKey === 'runnerEmail' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Total venta',
            dataIndex: 'orderTotal',
            key: 'orderTotal',
            sorter: (a, b) => a.orderTotal - b.orderTotal,
            sortOrder:
                sortedInfo.columnKey === 'orderTotal' && sortedInfo.order,
            ellipsis: true,
            render: (orderTotal) => <p>$ {formatMoney(orderTotal)}</p>,
        },
        {
            title: 'Pago a runner',
            dataIndex: 'commission',
            key: 'commission',
            sorter: (a, b) => a.commission - b.commission,
            sortOrder:
                sortedInfo.columnKey === 'commission' && sortedInfo.order,
            ellipsis: true,
            render: (commission) => <p>$ {formatMoney(commission)}</p>,
        },
        {
            title: 'Propina',
            dataIndex: 'tip',
            key: 'tip',
            width: 100,
            sorter: (a, b) => a.tip - b.tip,
            sortOrder: sortedInfo.columnKey === 'tip' && sortedInfo.order,
            ellipsis: true,
            render: (tip) => <p>$ {formatMoney(tip)}</p>,
        },
        {
            title: 'Tipo de Pago',
            dataIndex: 'orderPayType',
            key: 'orderPayType',
            ellipsis: true,
            width: 150,
            align: 'center',
            filters: [
                { text: 'Efectivo', value: 1 },
                { text: 'Tarjeta', value: 2 },
            ],
            filteredValue: filteredInfo.orderPayType || null,
            onFilter: (value, record) => record.orderPayType === value,
            sorter: (a, b) => a.orderPayType.length - b.orderPayType.length,
            sortOrder: sortedInfo.columnKey === 'payType' && sortedInfo.order,
            render: (orderPayType) => (
                <p>
                    {orderPayType === 1 ? (
                        <DollarOutlined style={{ marginRight: 6 }} />
                    ) : (
                        <CreditCardOutlined style={{ marginRight: 6 }} />
                    )}
                    {orderPayType === 1 ? 'Efectivo' : 'Tarjeta'}
                </p>
            ),
        },
        {
            title: 'Estatus de Pago',
            dataIndex: 'isPaidOut',
            key: 'isPaidOut',
            width: 180,
            filters: [
                { text: 'Liquidado', value: true },
                { text: 'Pendiente', value: false },
            ],
            filteredValue: filteredInfo.isPaidOut || null,
            onFilter: (value, record) => record.isPaidOut === value,
            sorter: (a, b) => a.isPaidOut.length - b.isPaidOut.length,
            sortOrder: sortedInfo.columnKey === 'isPaidOut' && sortedInfo.order,
            ellipsis: true,
            render: (isPaidOut) => (
                <Tag color={isPaidOut === true ? 'green' : 'volcano'}>
                    {isPaidOut === true ? 'LIQUIDADO' : 'PENDIENTE'}
                </Tag>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'deliveryStatus',
            key: 'deliveryStatus',
            ellipsis: true,
            align: 'center',
            width: 180,
            filters: [
                { text: 'Entregado', value: 4 },
                { text: 'Pagada', value: 5 },
                { text: 'Cancelada', value: 6 },
                { text: 'Rechazada', value: 7 },
            ],
            filteredValue: filteredInfo.deliveryStatus || null,
            onFilter: (value, record) => record.deliveryStatus === value,
            sorter: (a, b) => a.deliveryStatus.length - b.deliveryStatus.length,
            sortOrder: sortedInfo.columnKey === 'payType' && sortedInfo.order,
            render: (deliveryStatus) => (
                <p>
                    {deliveryStatus === 4
                        ? 'Entregado'
                        : deliveryStatus === 5
                        ? 'Pagada'
                        : deliveryStatus === 6
                        ? 'Cancelada'
                        : 'Rechazada'}
                </p>
            ),
        },
    ];
    const createValueTable = (res) => {
        if (res !== undefined) {
            // setFilteredInfo({});
            // setSortedInfo({});
            let arrayOrder = [];
            let profitApi = 0;
            let totalSell = 0;
            let totalEfectivo = 0;
            let totalTarjeta = 0;
            let deudaRunner = 0;
            let deudaCompany = 0;
            res.forEach((element) => {
                element['key'] = element.orderId;
                element['date'] = moment
                    .unix(element.deliveryDate)
                    .format('DD/MM/YYYY, h:mm a');
                element['totalPrice'] = `$ ${formatMoney(element.orderTotal)}`;

                arrayOrder.push(element);

                if (element.deliveryStatus === 5) {
                    totalSell = totalSell + 1;
                    profitApi = profitApi + element.orderTotal;
                    if (element.orderPayType === 1) {
                        totalEfectivo += element.orderTotal;
                        if (!element.isPaidOut) {
                            deudaRunner +=
                                element.orderTotal - element.commission;
                        }
                    }
                    if (element.orderPayType === 2) {
                        totalTarjeta += element.orderTotal;
                        if (!element.isPaidOut) {
                            deudaCompany += element.commission;
                        }
                    }
                }
            });

            if (filteredInfo.deliveryStatus) {
                if (
                    filteredInfo.deliveryStatus[0] === 'Cancelada' ||
                    filteredInfo.deliveryStatus[0] === 'Rechazada'
                ) {
                    profitApi = 0;
                    totalSell = 0;
                    totalEfectivo = 0;
                    totalTarjeta = 0;
                    deudaRunner = 0;
                    deudaCompany = 0;
                }
            }

            setOrdersPdf(arrayOrder);
            setStaticData({
                Profits: profitApi,
                TotalSells: totalSell,
                TotalEfectivo: totalEfectivo,
                TotalTarjeta: totalTarjeta,
                DeudaRunner: deudaRunner,
                DeudaCompany: deudaCompany,
            });
            if (res.length > 0) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
        setLoading(false);
    };
    const exportPdf = () => {
        var mstrTitulo = `Reporte de ventas envió a domicilio`;
        var mstrPdf = 'reporte_ventas_domicilio.pdf';

        var registros = [];
        let total_Sell = 0;
        let total_Profits = 0;
        let orders = ordersPdf;
        let totalEfectivo = 0;
        let totalTarjeta = 0;
        let deudaRunner = 0;
        let deudaCompany = 0;

        orders.forEach((element) => {
            if (element.deliveryStatus === 5) {
                total_Sell++;
                total_Profits = total_Profits + element.orderTotal;
                if (element.orderPayType === 1) {
                    totalEfectivo += element.orderTotal;
                    if (!element.isPaidOut) {
                        deudaRunner += element.orderTotal - element.commission;
                    }
                }
                if (element.orderPayType === 2) {
                    totalTarjeta += element.orderTotal;
                    if (!element.isPaidOut) {
                        deudaCompany += element.commission;
                    }
                }
            }

            let deliveryStatus = '';
            switch (element.deliveryStatus) {
                case 4:
                    deliveryStatus = 'Entregada';
                    break;
                case 5:
                    deliveryStatus = 'Pagada';
                    break;
                case 6:
                    deliveryStatus = 'Cancelada';
                    break;
                case 7:
                    deliveryStatus = 'Rechazada';
                    break;

                default:
                    break;
            }

            let orderPayType = '';
            if (element.orderPayType === 1) {
                orderPayType = 'Efectivo';
            } else {
                orderPayType = 'Tarjeta';
            }

            registros.push([
                element.orderNum,
                moment.unix(element.deliveryDate).format('DD-MM-YYYY, h:mm a'),
                element.runnerEmail,
                '$ ' + formatMoney(element.orderTotal),
                '$ ' + formatMoney(element.commission),
                '$ ' + formatMoney(element.tip),
                orderPayType,
                deliveryStatus,
            ]);
        });

        registros.push(
            ['Ventas Totales', total_Sell],
            ['Total de ventas a domicilio', '$ ' + formatMoney(total_Profits)],
            [
                'Total de ventas pago en efectivo',
                '$ ' + formatMoney(totalEfectivo),
            ],
            [
                'Total de ventas pago con tarjeta',
                '$ ' + formatMoney(totalTarjeta),
            ],
            [
                'Deuda runners con establecimiento',
                '$ ' + formatMoney(deudaRunner),
            ],
            [
                'Deuda establecimiento con runners',
                '$ ' + formatMoney(deudaCompany),
            ]
        );

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 245;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };
        doc.autoTable({
            head: [
                [
                    '# Orden',
                    'Fecha',
                    'Runner',
                    'Total',
                    'Pago a runner',
                    'Propina',
                    'Tipo de pago',
                    'Estatus',
                ],
            ],
            didDrawPage: pageContent,
            theme: 'grid',
            headStyles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [49, 101, 120],
                textColor: [255, 255, 255],
            },
            body: registros,
            columnStyles: {
                text: {
                    cellWidth: 'wrap',
                },
            },
        });

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };
    const showModal = async (orders) => {
        setIsModalVisible(true);
        let lastCashCut = 0;
        getLastDateCashCut(token).then((response) => {
            if (response.statusCode === 200) {
                lastCashCut = response.result.lastCashCutDate;

                setContextModal(
                    <CorteDeCaja orders={orders} lastCashCut={lastCashCut} />
                );
            } else {
                notification['error']({
                    message: response.message,
                });
            }
        });
    };
    return (
        <div>
            <>
                <Space style={{ marginBottom: 16 }}>
                    <RangePicker
                        onChange={onChangeDate}
                        defaultValue={[
                            moment(startDate, 'YYYY-MM-DD'),
                            moment(endDate, 'YYYY-MM-DD'),
                        ]}
                        showTime={{ format: 'HH:mm' }}
                        format="DD-MM-YYYY HH:mm"
                    />

                    <Col md={10} xs={24}>
                        <Select
                            style={{ width: 160 }}
                            placeholder="Filtrar por grupo"
                            defaultValue={null}
                            onChange={onChangeRunnerGroup}
                            value={idRunnerGroup}
                        >
                            {runnerGroups.map((option) => (
                                <Select.Option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    {runnerListByGroup.length > 0 && (
                        <Col xs={24} md={24}>
                            <Select
                                allowClear
                                showSearch
                                style={{ width: 160 }}
                                placeholder="Busqueda por runner"
                                optionFilterProp="children"
                                onChange={onChangeSelectRunner}
                                value={runnerName}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {runnerListByGroup.map((user) => {
                                    return (
                                        <Option
                                            key={user.id}
                                            value={user.email}
                                        >
                                            {user.email}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    )}
                    <Button onClick={clearAll}>Limpiar Filtros</Button>
                </Space>
                {/* <Col span={24} xs={24} md={20}>
          <Paragraph>
            Búsqueda del:{" "}
            <Text strong>
              {moment.unix(dateRecord.startDate).format("DD-MM-YYYY HH:mm")}{" "}
            </Text>
            al{" "}
            <Text strong>
              {moment.unix(dateRecord.endDate).format("DD-MM-YYYY HH:mm")}
            </Text>
          </Paragraph>
        </Col> */}
                <Spin spinning={loading} tip="Cargando...">
                    <Table
                        columns={columns}
                        dataSource={ordersRunners}
                        onChange={handleChange}
                        scroll={{ x: 850 }}
                    ></Table>
                </Spin>

                <Row gutter={16} className={'footer-section-report'}>
                    <Col md={10} xs={24}>
                        <h2>Información de ventas</h2>
                        <Row>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Total de Ventas"
                                    value={staticData.TotalSells}
                                />
                            </Col>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Total de ventas a domicilio"
                                    value={`$ ${formatMoney(
                                        staticData.Profits
                                    )}`}
                                    precision={2}
                                />
                            </Col>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Total de ventas pago en efectivo"
                                    value={`$ ${formatMoney(
                                        staticData.TotalEfectivo
                                    )}`}
                                    precision={2}
                                />
                            </Col>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Total de ventas pago con tarjeta"
                                    value={`$ ${formatMoney(
                                        staticData.TotalTarjeta
                                    )}`}
                                    precision={2}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <div className="linea-vertical"></div>
                    <Col md={10} xs={24}>
                        <h2>Información pago y cobro</h2>
                        <Row>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Deuda runners con establecimiento"
                                    value={`$ ${formatMoney(
                                        staticData.DeudaRunner < 0
                                            ? 0
                                            : staticData.DeudaRunner
                                    )}`}
                                />
                                {staticData.DeudaRunner < 0 && (
                                    <Paragraph type="danger">
                                        Se le debe $
                                        {formatMoney(
                                            staticData.DeudaRunner * -1
                                        )}{' '}
                                        los cuales se descontaran de los
                                        próximos pedidos en efectivo
                                    </Paragraph>
                                )}
                            </Col>
                            <Col md={12} xs={24}>
                                <Statistic
                                    title="Deuda establecimiento con runners"
                                    value={`$ ${formatMoney(
                                        staticData.DeudaCompany
                                    )}`}
                                    precision={2}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} xs={24} className="text-right">
                        <Button
                            type="primary"
                            size="large"
                            icon={<FilePdfOutlined />}
                            onClick={exportPdf}
                            disabled={disabledButton}
                        >
                            Exportar Pdf
                        </Button>
                        <Button
                            style={{
                                marginTop: 15,
                                backgroundColor: 'green',
                                borderColor: 'green',
                            }}
                            type="primary"
                            size="large"
                            icon={<CarryOutOutlined />}
                            onClick={() => showModal(ordersRunners)}
                            disabled={disabledButton}
                        >
                            Corte de caja
                        </Button>
                    </Col>
                </Row>
                <Modal
                    isVisible={isModalVisible}
                    setIsVisible={setIsModalVisible}
                    title="Corte de caja"
                    footer={false}
                    destroyOnClose
                >
                    {contextModal}
                </Modal>
            </>
        </div>
    );
};
function CorteDeCaja(props) {
    const { lastCashCut } = props;
    const dateFormat = 'YYYY-MM-DD';
    const token = getAccessTokenApi();
    const [lastDate, setLastDate] = useState();
    const [ordersCashCut, setOrdersCashCut] = useState();
    const [lastOrdersCashCut, setLastOrdersCashCut] = useState([]);
    const [searchOrdersIsVisible, setSearchOrdersIsVisible] = useState(true);
    const [exportPdfIsVisible, setExportPdfIsVisible] = useState(false);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);

    useEffect(() => {
        getLastCashCut(token).then((result) => {
            if (result.statusCode === 200) {
                setLastOrdersCashCut(result.result.deliveries);
                setStartDate(result.result.startDate);
                setEndDate(result.result.endDate);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchOrders = () => {
        if (lastDate) {
            getOrdersCashCut(token, lastDate).then((response) => {
                if (response.statusCode === 200) {
                    setOrdersCashCut(response.result.deliveries);
                    setSearchOrdersIsVisible(false);
                    setExportPdfIsVisible(true);
                } else {
                    notification['error']({
                        message: response.description,
                    });
                }
            });
        } else {
            notification['error']({
                message: 'Debe seleccionar una fecha',
            });
        }
    };
    const createCashCut = () => {
        const data = {
            endDate: lastDate,
        };
        postCreateCashCut(token, data).then((response) => {
            if (response.statusCode === 200) {
                setSearchOrdersIsVisible(true);
                setExportPdfIsVisible(false);
                notification['success']({
                    message: 'Se generó el corte correctamente',
                });
            } else {
                notification['error']({
                    message: response.description,
                });
            }
        });
    };

    function onChangeLastDate(date) {
        setSearchOrdersIsVisible(true);
        setExportPdfIsVisible(false);

        if (date) {
            setLastDate(date.unix());
        }
    }

    const exportPdf = (orders, startDate, endDate) => {
        var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
            .unix(startDate)
            .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
        var mstrPdf = 'reporte_cortes_domicilio.pdf';

        var registros = [];
        let total_Sell = orders.length;
        let total_Profits = 0;
        // let orders = ordersCashCut;
        let totalEfectivo = 0;
        let totalTarjeta = 0;
        let deudaRunner = 0;
        let deudaCompany = 0;

        orders.forEach((element) => {
            total_Profits = total_Profits + element.orderTotal;
            if (element.orderPayType === 1) {
                totalEfectivo += element.orderTotal;
                if (!element.isPaidOut) {
                    deudaRunner +=
                        element.orderTotal - element.runnerCommission;
                }
            }
            if (element.orderPayType === 2) {
                totalTarjeta += element.orderTotal;
                if (!element.isPaidOut) {
                    deudaCompany += element.runnerCommission;
                }
            }

            let orderPayType = '';
            if (element.orderPayType === 1) {
                orderPayType = 'Efectivo';
            } else {
                orderPayType = 'Tarjeta';
            }

            registros.push([
                element.orderNum,
                moment.unix(element.deliveryDate).format('DD-MM-YYYY, h:mm a'),
                element.runnerEmail,
                '$ ' + formatMoney(element.orderTotal),
                '$ ' + formatMoney(element.runnerCommission),
                '$ ' + formatMoney(element.tip),
                orderPayType,
            ]);
        });

        registros.push(
            ['Ventas Totales', total_Sell],
            ['Total de ventas a domicilio', '$ ' + formatMoney(total_Profits)],
            [
                'Total de ventas pago en efectivo',
                '$ ' + formatMoney(totalEfectivo),
            ],
            [
                'Total de ventas pago con tarjeta',
                '$ ' + formatMoney(totalTarjeta),
            ],
            [
                'Deuda runners con establecimiento',
                '$ ' + formatMoney(deudaRunner),
            ],
            [
                'Deuda establecimiento con runners',
                '$ ' + formatMoney(deudaCompany),
            ]
        );

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 245;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(-10, -10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };
        doc.autoTable({
            head: [
                [
                    '# Orden',
                    'Fecha',
                    'Runner',
                    'Total',
                    'Pago a runner',
                    'Propina',
                    'Tipo de pago',
                ],
            ],
            didDrawPage: pageContent,
            theme: 'grid',
            headStyles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [49, 101, 120],
                textColor: [255, 255, 255],
            },
            body: registros,
            columnStyles: {
                text: {
                    cellWidth: 'wrap',
                },
            },
        });

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };

    return (
        <>
            {lastOrdersCashCut.length > 0 && (
                <Row>
                    <h4>
                        La fecha del ultimo corte es:{' '}
                        {moment.unix(endDate).format('LL')}
                    </h4>
                    <Col>
                        <Button
                            // style={{ width: "100%", marginTop: 20 }}
                            type="primary"
                            size="small"
                            icon={<DownloadOutlined />}
                            // onClick={exportPdf}
                            onClick={() =>
                                exportPdf(lastOrdersCashCut, startDate, endDate)
                            }
                        >
                            Descarga el último corte aquí
                        </Button>
                    </Col>
                </Row>
            )}

            <h4>Fecha inicio de corte: </h4>
            <DatePicker
                className={'picker-widt'}
                defaultValue={moment.unix(lastCashCut, dateFormat)}
                showTime={{ format: 'HH:mm' }}
                format="DD-MM-YYYY HH:mm"
                disabled
            />
            <h4 style={{ marginTop: 10 }}>Fecha final de corte: </h4>

            <DatePicker
                className={'picker-widt'}
                onChange={onChangeLastDate}
                showTime={{ format: 'HH:mm' }}
                format="DD-MM-YYYY HH:mm"
            />
            {searchOrdersIsVisible && (
                <Col md={24} xs={24} className="text-right">
                    <Button
                        style={{ width: '100%', marginTop: 20 }}
                        type="primary"
                        size="large"
                        icon={<FileSearchOutlined />}
                        onClick={searchOrders}
                    >
                        Buscar ordenes para realizar corte
                    </Button>
                </Col>
            )}
            {exportPdfIsVisible && (
                <>
                    <Col md={24} xs={24} className="text-right">
                        <Button
                            style={{ width: '100%', marginTop: 20 }}
                            type="primary"
                            size="large"
                            icon={<FilePdfOutlined />}
                            // onClick={exportPdf}
                            // onClick={(ordersCashCut) => exportPdf}
                            onClick={() =>
                                exportPdf(ordersCashCut, lastCashCut, lastDate)
                            }
                        >
                            Descargar corte
                        </Button>
                    </Col>
                    <Col md={24} xs={24} className="text-right">
                        <Button
                            style={{
                                width: '100%',
                                marginTop: 20,
                                backgroundColor: 'green',
                                borderColor: 'green',
                            }}
                            type="primary"
                            size="large"
                            icon={<CarryOutOutlined />}
                            onClick={createCashCut}
                        >
                            Generar corte
                        </Button>
                    </Col>
                </>
            )}
        </>
    );
}
export default HomeDeliveries;
