import LayoutAdmin from '../layout/LayoutAdmin';
import LayoutBasic from '../layout/LayoutBasic';
import LayoutClient from '../layout/LayoutClient';
import LayoutRunner from '../layout/LayoutRunner';

//Admin Pages
import Perfil from '../pages/Admin/Perfil';
import AdminMenu from '../pages/Admin/Menu';
import Orders from '../pages/Admin/Orders';
import OrderPrint from '../pages/Admin/OrderPrint';
import ChangePassword from '../pages/Admin/ChangePassword';
import Report from '../pages/Admin/Report';
import PolicyAdmin from '../pages/Admin/Policy';
import SuccessStripeAccount from '../pages/Admin/SuccessStripeAccount';
import ErrorStripeAccount from '../pages/Admin/ErrorStripeAccount';
import Collaborators from '../pages/Admin/Collaborators';
import Promotions from '../pages/Admin/Promotions';
import Runners from '../pages/Admin/Runners';

//Client Pages
import Client from '../pages/Client';
import Menu from '../pages/Client/MenuCustome';
import StatusOrder from '../pages/Client/StatusOrder';
import Policy from '../pages/Client/Policy';
import OrderHistory from '../pages/Client/OrderHistory';
import Help from '../pages/Client/Help';
import PerfilClient from '../pages/Client/Perfil';
import ChangePasswordClient from '../pages/Client/ChangePassword';
import PaymentConfig from '../pages/Client/PaymentConfig';
import DisableRestaurant from '../pages/Client/DisableRestaurant';
import CashPayment from '../pages/Client/CashPayment';
import CreditCards from '../pages/Client/CreditCards';
import PaymentCard from '../pages/Client/PaymentCard';
import ListOrdersActive from '../pages/Client/ListOrdersActive';

//Runners Pages
import RunnerPage from '../pages/Runner';
import RunnerOrders from '../pages/Runner/Orders';
import RunnerHistory from '../pages/Runner/OrderHistory';
import ActiveOrdersList from '../pages/Runner/ActiveOrdersList';
import OrderActive from '../pages/Runner/OrderActive/OrderActive';
import HelpRunner from '../pages/Runner/Help';

//Pages
import Login from '../pages/Login';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Updates from '../pages/Updates';
import ConfirmEmail from '../pages/ConfirmEmail';
import ValidateEmail from '../pages/ValidateEmail';

//other
import Error404 from '../pages/Error404';

const routes = [
    {
        path: '/login',
        component: LayoutBasic,
        exact: true,
        routes: [
            {
                path: '/login',
                component: Login,
                exact: true,
            },
        ],
    },
    {
        path: '/registro',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/registro',
                component: Register,
                exact: true,
            },
        ],
    },
    {
        path: '/resetPassword',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/resetPassword',
                component: ResetPassword,
                exact: true,
            },
        ],
    },
    {
        path: '/confirmEmail',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/confirmEmail',
                component: ConfirmEmail,
                exact: true,
            },
        ],
    },
    {
        path: '/validateEmail/:email',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/validateEmail/:email',
                component: ValidateEmail,
                exact: true,
            },
        ],
    },
    {
        path: '/admin',
        component: LayoutAdmin,
        exact: false,
        routes: [
            {
                path: '/admin',
                component: Perfil,
                exact: true,
            },
            {
                path: '/admin/menu',
                component: AdminMenu,
                exact: true,
            },
            {
                path: '/admin/orden',
                component: Orders,
                exact: true,
            },
            {
                path: '/admin/changePassword',
                component: ChangePassword,
                exact: true,
            },
            {
                path: '/admin/print',
                component: OrderPrint,
                exact: true,
            },
            {
                path: '/admin/updates',
                component: Updates,
                exact: true,
            },
            {
                path: '/admin/report',
                component: Report,
                exact: true,
            },
            {
                path: '/admin/policy',
                component: PolicyAdmin,
                exact: true,
            },
            {
                path: '/admin/stripe/account/success',
                component: SuccessStripeAccount,
                exact: true,
            },
            {
                path: '/admin/stripe/account/error',
                component: ErrorStripeAccount,
                exact: true,
            },
            // {
            //     path: '/admin/collaborators',
            //     component: Collaborators,
            //     exact: true,
            // },
            {
                path: '/admin/promotions',
                component: Promotions,
                exact: true,
            },
            {
                path: '/admin/runners',
                component: Runners,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
    {
        path: '/runner',
        component: LayoutRunner,
        exact: false,
        routes: [
            {
                path: '/runner',
                component: RunnerPage,
                exact: true,
            },
            {
                path: '/runner/changePassword',
                component: ChangePassword,
                exact: true,
            },
            {
                path: '/runner/orders',
                component: RunnerOrders,
                exact: true,
            },
            {
                path: '/runner/policy',
                component: PolicyAdmin,
                exact: true,
            },
            {
                path: '/runner/updates',
                component: Updates,
                exact: true,
            },
            {
                path: '/runner/orderHistory',
                component: RunnerHistory,
                exact: true,
            },
            {
                path: '/runner/active/deliveries',
                component: ActiveOrdersList,
                exact: true,
            },
            {
                path: '/runner/help/',
                component: HelpRunner,
                exact: true,
            },
            {
                path: '/runner/delivery/:idDelivery',
                component: OrderActive,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
    {
        path: '/',
        component: LayoutClient,
        exact: false,
        routes: [
            {
                path: '/',
                component: Client,
                exact: true,
            },
            {
                path: '/menu',
                component: Menu,
                exact: true,
            },
            {
                path: '/statusOrder/:idOrder',
                component: StatusOrder,
                exact: true,
            },
            {
                path: '/updates',
                component: Updates,
                exact: true,
            },
            {
                path: '/policy',
                component: Policy,
                exact: true,
            },
            {
                path: '/help',
                component: Help,
                exact: true,
            },
            {
                path: '/payment',
                component: PaymentConfig,
                exact: true,
            },
            {
                path: '/orders/history',
                component: OrderHistory,
                exact: true,
            },
            {
                path: '/perfil',
                component: PerfilClient,
                exact: true,
            },
            {
                path: '/password',
                component: ChangePasswordClient,
                exact: true,
            },
            {
                path: '/disable/restaurant',
                component: DisableRestaurant,
                exact: true,
            },
            {
                path: '/payment/cash',
                component: CashPayment,
                exact: true,
            },
            {
                path: '/payment/cards',
                component: CreditCards,
                exact: true,
            },
            {
                path: '/payment/cards/:cardId',
                component: PaymentCard,
                exact: true,
            },
            {
                path: '/orders/active',
                component: ListOrdersActive,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
];

export default routes;
