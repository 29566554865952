import React from 'react';
import {Divider, Layout, Typography } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import fileTerminos from '../../../assets/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf';
import fileAvisoPrivacidad  from '../../../assets/pdf/AVISO-DE-PRIVACIDAD-DE-CONTROL-B.pdf';
import { isMobile } from '../../../utils/general';
import { getMobileOperatingSystem } from '../../../utils/subscriptions';

import './Policy.scss';

const Policy = () => {

    const { Content } = Layout;
    const { Title } = Typography;

    // const downloadFile = (fileUrl, nameFile) =>{
    //     let a = document.createElement('a');
    //     a.href = fileUrl;
    //     a.download = nameFile;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // }

    return (
        <Layout className="policy">
            <Content className="policy_content">
                <HeaderTop 
                    title={"Términos y Condiciones"} 
                    backIcon={true} 
                    backUrl={'/admin'}
                />

                <div className="policy__container">
                    <Title level={4}>TERMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL B</Title>

                    {!isMobile() && <embed src={fileTerminos} type="application/pdf" width="100%" height="600px" />}
                    
                    { getMobileOperatingSystem() !== 'iOS' &&  
                        <a href={fileTerminos} rel="noopener noreferrer" target="_blank"> TERMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL B.pdf</a>
                    }
                    
                    {/* <Button 
                        type="link" 
                        onClick={()=>downloadFile(fileTerminos, 'TERMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL B.PDF')}
                    >
                        TERMINOS DE USO DEL PRODUCTO.pdf
                    </Button> */}

                    {getMobileOperatingSystem() === 'iOS' &&
                        <a 
                            href={"http://controlb.controlbsys.com/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf"} 
                            
                            target="_blank" rel="noopener noreferrer" >TERMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL B.pdf
                        </a>
                    }
                </div>

                <Divider></Divider>

                <div className="policy__container">
                    <Title level={4}>AVISO DE PRIVACIDAD DE CONTROL B</Title>

                    {!isMobile() && <embed src={fileAvisoPrivacidad} type="application/pdf" width="100%" height="600px" />}

                    {getMobileOperatingSystem() !== 'iOS' &&  
                        <a href={fileAvisoPrivacidad} rel="noopener noreferrer" target="_blank"> AVISO DE PRIVACIDAD DE CONTROL B.pdf</a>
                    }

                    {/* <Button 
                        type="link" 
                        onClick={()=>downloadFile(fileAvisoPrivacidad, "AVISO DE PRIVACIDAD DE CONTROL B.PDF")}
                    >
                            AVISO DE PRIVACIDAD DE CONTROL B.pdf
                    </Button> */}

                    {getMobileOperatingSystem() === 'iOS' &&

                        <a href={"http://controlb.controlbsys.com/pdf/AVISO-DE-PRIVACIDAD-DE-CONTROL-B.pdf"} rel="noopener noreferrer" target="_blank">AVISO DE PRIVACIDAD DE CONTROL B.pdf</a>
                    }
                    
                </div>
            </Content>
        </Layout>
    )
}

export default Policy;
