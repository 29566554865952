import { basePath } from "./config";
import { notification } from "antd";

// GET GRUPOS
export function getRunnerGroups(token) {
  const url = `${basePath}/admin/runner-group`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

// GET GRUPO
export function getRunnerGroup(token, idGroup) {
  const url = `${basePath}/admin/runner-group/${idGroup}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

// AGREGAR GRUPO RUNNE
export function addGroupRunner(token, data) {
  const url = `${basePath}/admin/runner-group`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// ELIMINAR GRUPO
export function deleteGroupRunner(token, idGroup) {
  const url = `${basePath}/admin/runner-group/${idGroup}`;

  const params = {
    method: "DELETE",
    // body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}
// EDITAR GRUPO
export function editGroupRunner(token, data, idGroup) {
  const url = `${basePath}/admin/runner-group/${idGroup}`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// AGREGAR RUNNER EN UN GRUPO
export function addRunner(token, data) {
  const url = `${basePath}/admin/runner-group/${data.GroupId}/runner`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// GET RUNNER ADMIN
export function getAdminRunner(token, id) {
  const url = `${basePath}/admin/runner/${id}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// EDITAR ADMIN RUNNER
export function editAdminRunner(token, data) {
  const url = `${basePath}/admin/runner/${data.id}`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// ELIMINAR RUNNER
export function deleteRunner(token, id) {
  const url = `${basePath}/admin/runner/${id}`;

  const params = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// DENEGAR AUTORIZACION AL RUNNER
export function refuseAuthorizationRunner(token, data, id) {
  const url = `${basePath}/admin/runner/${id}/refuse-authorization`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

// GET EMAIL RUNNERS
export function getEmailRunners(token, email) {
  const url = `${basePath}/Users/GetAll?Email=${email} `;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

// GET RUNNER
export function getRunner(token, id) {
  const url = `${basePath}/runner/${id}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// EDITAR RUNNER
export function editRunner(token, data) {
  const url = `${basePath}/runner/${data.id}`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//GET COMPANIES RUNNER
export function getCompaniesRunner(token) {
  const url = `${basePath}/runner/company`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}
// CAMBIAR STATUS ONLINE DEL RUNNER
export function onlineStatusRunner(token, data) {
  const url = `${basePath}/runner/online-status`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//GET ORDERS RUNNER
export function getOrdersRunner(token) {
  const url = `${basePath}/runner/order`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

// TOMAR UNA ORDEN
export function takeOrderApi(token, data) {
  const url = `${basePath}/runner/delivery`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

//GET DELIVERY RUNNER
export function getDeliveryRunnerApi(token, id) {
  const url = `${basePath}/runner/delivery/${id}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}
// RUNNER- ACTUALIZAR DELIVERY Y ORDEN
export function updateOrderRunner(token, data) {
  const url = `${basePath}/runner/delivery/${data.id}`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//GET ORDENES ACTIVAS
export function getActiveOrdersRunnerApi(token) {
  const url = `${basePath}/runner/delivery/active`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}
//GET ORDENES ADMIN RECORDS
export function getRecordsOrdersRunnerApi(token, filter) {
  const url = `${basePath}/admin/delivery/${filter}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

//GET RUNNERS RECORDS
export function getRecordsRunnerApi(token, filter) {
  const url = `${basePath}/runner/delivery/records${filter}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

// AGREGAR DOCUEMNTOS RUNNER
export function addDocumentsRunner(token, data) {
  const url = `${basePath}/runner/document`;

  const formData = new FormData();

  if (data.CirculationCardFront) {
    if (data.CirculationCardFront.length > 0) {
      formData.append(
        "CirculationCardFront",
        data["CirculationCardFront"][0].originFileObj
      );
    }
  }

  if (data.CirculationCardBack) {
    if (data.CirculationCardBack.length > 0) {
      formData.append(
        "CirculationCardBack",
        data["CirculationCardBack"][0].originFileObj
      );
    }
  }

  if (data.DriversLicenseFront) {
    if (data.DriversLicenseFront.length > 0) {
      formData.append(
        "DriversLicenseFront",
        data["DriversLicenseFront"][0].originFileObj
      );
    }
  }

  if (data.DriversLicenseBack) {
    if (data.DriversLicenseBack.length > 0) {
      formData.append(
        "DriversLicenseBack",
        data["DriversLicenseBack"][0].originFileObj
      );
    }
  }

  formData.append(
    "DriversLicenseExpirationDate",
    data.DriversLicenseExpirationDate
  );
  formData.append(
    "CirculationCardExpirationDate",
    data.CirculationCardExpirationDate
  );

  console.log(formData);

  const params = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

//GET EARNING RUNNERS
export function getEarningsRunnerApi(token) {
  const url = `${basePath}/runner/delivery/earnings-of-day`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

//GET DOCUMENTS RUNNERS
export function getDocumentsRunnerApi(token) {
  const url = `${basePath}/runner/document`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

//DOWNLOAD DOCUMENTS RUNNERS
export function downloadDocumentsRunnerApi(token, id) {
  const url = `${basePath}/runner/document/${id}/download`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//GET RUNNER INFO EN ORDER DESCRIPTION
export function getRunnerOrderDetail(token, id) {
  const url = `${basePath}/Order/${id}/runner`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

//GET DOCUMENTS RUNNERS BY ADMIN
export function getDocumentsRunnerAdminApi(token, membership) {
  const url = `${basePath}/admin/runner/${membership}/documents`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

// AGREGAR DOCUEMNTOS RUNNER
export function updateDocumentsRunner(token, data) {
  const url = `${basePath}/runner/document`;

  const formData = new FormData();

  if (data.CirculationCardFront) {
    if (data.CirculationCardFront.length > 0) {
      formData.append(
        "CirculationCardFront",
        data["CirculationCardFront"][0].originFileObj
      );
    }
  }

  if (data.CirculationCardBack) {
    if (data.CirculationCardBack.length > 0) {
      formData.append(
        "CirculationCardBack",
        data["CirculationCardBack"][0].originFileObj
      );
    }
  }

  if (data.DriversLicenseFront) {
    if (data.DriversLicenseFront.length > 0) {
      formData.append(
        "DriversLicenseFront",
        data["DriversLicenseFront"][0].originFileObj
      );
    }
  }

  if (data.DriversLicenseBack) {
    if (data.DriversLicenseBack.length > 0) {
      formData.append(
        "DriversLicenseBack",
        data["DriversLicenseBack"][0].originFileObj
      );
    }
  }

  formData.append(
    "DriversLicenseExpirationDate",
    data.DriversLicenseExpirationDate
  );
  formData.append(
    "CirculationCardExpirationDate",
    data.CirculationCardExpirationDate
  );

  console.log(formData);

  const params = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

// ELIMINAR DOCUEMNTOS RUNNER
export function deleteDocumentsRunner(token, membership, docId) {
  const url = `${basePath}/admin/runner/${membership}/document/${docId}`;

  const params = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
//GET DEDUDAS ENTRE RUNNER Y COMPANY
export function getCompanyDebtRunnerApi(token, filter, id) {
  const url = `${basePath}/runner/company/${id}/bills${filter}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });
    });
}

export function cancelDeliveryRunnerApi(token, id) {
  const url = `${basePath}/runner/delivery/${id}/cancel`;

  const params = {
    method: "PUT",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function cancelDeliveryRunnerLocationApi(token, id, data) {
  const url = `${basePath}/runner/delivery/${id}/cancel`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//#region CORTE ADMIN
// GET ORDERS PARA CORTE
export function getOrdersCashCut(token, date) {
  const url = `${basePath}/admin/delivery/cash-cut?endDate=${date}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// GET FECHA DE ULTIMO CORTE
export function getLastDateCashCut(token) {
  const url = `${basePath}/admin/cash-cut/last-date`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// GET ULTIMO CORTE
export function getLastCashCut(token) {
  const url = `${basePath}/admin/cash-cut/last`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
// CREAR CORTE
export function postCreateCashCut(token, data) {
  const url = `${basePath}/admin/delivery/cash-cut`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}
//#endregion
