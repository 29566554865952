import React from 'react';
import { Modal as ModalAntd, Button } from 'antd';

export default function Modal(props) {

    const Close = () =>{
        setIsVisible(false)
    }

    const { 
        children, 
        title , 
        isVisible, 
        setIsVisible,
        onCancel = Close, 
        footer,
        destroyOnClose = false
    } = props;

    

    return (
        <ModalAntd
            destroyOnClose={destroyOnClose}
            style={{ top: 20 }}
            title={title}
            centered={false}
            visible={isVisible}
            onCancel={onCancel} 
            footer={ footer && ([
                <Button key="back" onClick={()=>setIsVisible(false)}>
                  Cancelar
                </Button>
              ])} 
        >
            {children}
        </ModalAntd>
    );
}
