import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    List,
    Card,
    Row,
    Col,
    Typography,
    Tag,
    Spin,
    notification,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Logo from '../../../assets/img/png/logoControlB.png';
import './ListActiveOrders.scss';
import { getActiveOrdersRunnerApi } from '../../../api/runner';
import { getAccessTokenApi } from '../../../api/auth';
import { userLogOut } from '../../../utils/general';

export default function ListActiveOrders() {
    let history = useHistory();
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const token = getAccessTokenApi();
    const [ActiveOrders, setActiveOrders] = useState([]);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getActiveOrdersHandler();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getActiveOrdersHandler = async () => {
        let ordenes = [];
        setLoading(true);
        const result = await getActiveOrdersRunnerApi(token);
        if (isMounted.current) {
            if (result) {
                ordenes = result.result;
                setActiveOrders(ordenes);
                setLoading(false);
            } else {
                notification['warning']({
                    message: 'Ocurrio un error, intentelo mas tarde.',
                });

                setTimeout(() => {
                    history.push('/runner');
                }, 500);
            }
        }
    };
    return (
        <div className="list-active-orders">
            <Spin spinning={loading} tip="Cargando...">
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 4,
                    }}
                    pagination={{
                        onChange: (page) => {
                            console.log(page);
                        },
                        pageSize: 3,
                    }}
                    itemLayout="horizontal"
                    dataSource={ActiveOrders}
                    renderItem={(item) => (
                        <List.Item>
                            <Orders item={item} />
                        </List.Item>
                    )}
                />
            </Spin>
        </div>
    );
}

function Orders(props) {
    const { Title, Text } = Typography;
    const { item } = props;

    let history = useHistory();
    const handleGoOrder = () => {
        history.push(`/runner/delivery/${item.id}`);
    };

    console.log(item);

    return (
        <Card className="card" onClick={handleGoOrder}>
            <Row>
                <Col span={8}>
                    <div className="avatar-company">
                        {item.companyImage && (
                            <img
                                className="logo"
                                src={`data:image/${item.companyImage.extension};base64, ${item.companyImage.imageData}`}
                                alt="logo de la compañia"
                            />
                        )}
                        {item.companyImage == null && (
                            <img
                                className="logo"
                                src={Logo}
                                alt="logo de la compañia"
                            />
                        )}
                    </div>
                </Col>
                <Col span={16}>
                    <div className="text-right">
                        <Tag className="order-number" color="#ff4d4f">
                            Orden: #{item.orderNum}
                        </Tag>
                    </div>
                    <Title level={5}>{item.companyName}</Title>
                    <Text type="secondary">
                        {' '}
                        {item.clientAddress} <RightOutlined className="right" />
                    </Text>
                </Col>
            </Row>
        </Card>
    );
}
