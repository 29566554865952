import React, { useRef, useEffect, useState } from 'react';
import {
    Divider,
    DatePicker,
    Typography,
    Tabs,
    Row,
    notification,
    Col,
    Statistic,
    Alert,
    Spin,
} from 'antd';
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ShopFilled,
    UserOutlined,
} from '@ant-design/icons';
import {
    getCompaniesRunner,
    getCompanyDebtRunnerApi,
} from '../../../api/runner';
import { getAccessTokenApi } from '../../../api/auth';
import { formatMoney, messageError } from '../../../utils/general';
import moment from 'moment';

import './EstablishmentAccount.scss';

function EstablishmentAccount() {
    const { RangePicker } = DatePicker;
    const { TabPane } = Tabs;
    const { Title, Paragraph, Text } = Typography;
    const isMounted = useRef(true);
    const token = getAccessTokenApi();
    const [companiesRunner, setCompaniesRunner] = useState([]);
    const [companyDebt, setCompanyDebt] = useState(0);
    const [runnerDebt, setRunnerDebt] = useState(0);
    const [dateRecord, setDateRecod] = useState({
        startDate: moment().subtract(7, 'days').unix(),
        endDate: moment().unix(),
    });
    const [loading, setLoading] = useState(true);

    const callback = async (key) => {
        const filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}`;

        const response = await getCompanyDebtRunnerApi(token, filters, key);
        if (response.statusCode === 200) {
            setCompanyDebt(response.result.companyDebt);
            setRunnerDebt(response.result.runnerDebt);
        } else {
            notification['error']({
                message: messageError(),
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getData();

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRecord]);

    const getData = async () => {
        const result = await getCompaniesRunner(token);
        if (result.statusCode === 200) {
            setCompaniesRunner(result.result);
            if (result.result.length > 0) {
                callback(result.result[0].companyId);
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }

        setLoading(false);
    };
    function onChangeDate(date, dateString) {
        if (date) {
            setDateRecod({
                startDate: date[0].unix(),
                endDate: date[1].unix(),
            });
        }
    }

    return (
        <div className="establishment-account">
            <Spin spinning={loading} tip="Cargando...">
                {companiesRunner.length === 0 && !loading && (
                    <Alert
                        message="No esta afiliado a ningún establecimiento."
                        description="Favor de ponerse en contácto."
                        type="warning"
                        showIcon
                    />
                )}
                {companiesRunner.length > 0 && !loading && (
                    <>
                        <div className="establishment-account__container-date">
                            <Row gutter={[0, 15]}>
                                <Col xs={24} md={12}>
                                    <RangePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="DD-MM-YYYY HH:mm"
                                        onChange={onChangeDate}
                                    />
                                </Col>
                                <Col span={24} xs={24} md={12}>
                                    <Paragraph>
                                        Búsqueda del:{' '}
                                        <Text strong>
                                            {moment
                                                .unix(dateRecord.startDate)
                                                .format(
                                                    'DD-MM-YYYY HH:mm'
                                                )}{' '}
                                        </Text>
                                        al{' '}
                                        <Text strong>
                                            {moment
                                                .unix(dateRecord.endDate)
                                                .format('DD-MM-YYYY HH:mm')}
                                        </Text>
                                    </Paragraph>
                                    <Divider />
                                </Col>
                            </Row>
                        </div>

                        <Divider>Compañias</Divider>

                        <div className="establishment-account__container">
                            <Tabs
                                onChange={callback}
                                type="card"
                                defaultActiveKey={companiesRunner[0]}
                            >
                                {companiesRunner.map((company) => {
                                    return (
                                        <TabPane
                                            tab={company.companyName}
                                            key={company.companyId}
                                        >
                                            <div className="tab-info">
                                                <Row>
                                                    <Col span={24}>
                                                        <Title level={4}>
                                                            {
                                                                company.companyName
                                                            }
                                                        </Title>
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Statistic
                                                            title={
                                                                <Paragraph className="subtitle">
                                                                    <ShopFilled />
                                                                    Deuda del
                                                                    Runner al
                                                                    establecimiento
                                                                </Paragraph>
                                                            }
                                                            value={formatMoney(
                                                                runnerDebt < 0
                                                                    ? 0
                                                                    : runnerDebt
                                                            )}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: '#cf1322',
                                                            }}
                                                            suffix={
                                                                <ArrowDownOutlined />
                                                            }
                                                            prefix="$"
                                                        />

                                                        {runnerDebt < 0 && (
                                                            <Paragraph type="danger">
                                                                Te deben $
                                                                {formatMoney(
                                                                    runnerDebt *
                                                                        -1
                                                                )}{' '}
                                                                esto se
                                                                descontara de
                                                                los próximos
                                                                pedidos en
                                                                efectivo
                                                            </Paragraph>
                                                        )}
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Statistic
                                                            title={
                                                                <Paragraph className="subtitle">
                                                                    <UserOutlined />
                                                                    Deuda del
                                                                    establecimiento
                                                                    al runner
                                                                </Paragraph>
                                                            }
                                                            value={formatMoney(
                                                                companyDebt
                                                            )}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: '#3f8600',
                                                            }}
                                                            suffix={
                                                                <ArrowUpOutlined />
                                                            }
                                                            prefix="$"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </div>
                    </>
                )}
            </Spin>
        </div>
    );
}

export default EstablishmentAccount;
