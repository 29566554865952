/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Form,
    Input,
    InputNumber,
    Button,
    Alert,
    Card,
    Image,
    notification,
    Divider,
    Row,
    Col,
    Typography,
} from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getAddressByZipcodeApi } from '../../../api/user';
import {
    getCompanyIdApi,
    createStripeSetupIntentApi,
    getStripePaymentMethodsApi,
} from '../../../api/company';
import VisaCard from '../../../assets/img/png/Visa-Emblema.png';
import MasterCard from '../../../assets/img/png/masterCard.png';
import AmericaExpress from '../../../assets/img/png/americaExpress.png';
import { userLogOut } from '../../../utils/general';

import './CheckoutForm.scss';

const CheckoutForm = ({
    setConfirmOrder,
    setStripePaymentMethod,
    setActiveSpin,
    setAcceptCardCredit,
}) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [showCardSave, setShowCardSave] = useState(false);
    const [cardSave, setCardSave] = useState(null);
    const [cardSelect, setCardSelect] = useState('');
    const [isValidCard, setIsValidCard] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showBillingInputs, setShowBillingInputs] = useState(false);

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const { Title } = Typography;
    const [form] = Form.useForm();

    useEffect(() => {
        //get client secret
        setActiveSpin(true);
        createStripeSetupIntentApi(token, companyId)
            .then((data) => {
                if (data === undefined) {
                    notification['error']({
                        message: 'No esta disponible el servicio.',
                    });
                    setActiveSpin(false);
                    return false;
                }

                if (data.statusCode === 200) {
                    setClientSecret(data.result.clientSecret);
                    // setStripePaymentIntent(data.result.id);
                    setActiveSpin(false);
                    stripeMethods();
                    setIsValidCard(true);
                    // setAcceptCardCredit(true);
                } else {
                    let msgError = '';
                    if (data.statusCode === 404) {
                        msgError = 'No esta disponible el servicio';
                    } else {
                        msgError = 'Ocurrió un error, intentelo mas tarde';
                    }

                    notification['error']({
                        message: msgError,
                    });
                    setActiveSpin(false);
                    setConfirmOrder(false);
                    setIsValidCard(false);
                    setAcceptCardCredit(false);
                }

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setActiveSpin(false);
                setConfirmOrder(false);
                setIsValidCard(false);
                setAcceptCardCredit(false);
                setLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} es requido',
        types: {
            email: '${label} no es un email válido!',
            number: '${label} no es un numero!',
        },
        number: {
            range: '${label} debe ser minimo ${min} and ${max}',
            length: '${label} debe de tener 5 dígitos',
        },
    };

    const stripeMethods = () => {
        getStripePaymentMethodsApi(token).then((data) => {
            console.log(data);
            if (data?.statusCode === 200) {
                if (data.result.length === 0) {
                    setShowCardSave(false);
                    setConfirmOrder(false);
                } else {
                    setCardSave(data.result);
                    setStripePaymentMethod(data.result[0]['id']);
                    setCardSelect(data.result[0]['id']);
                    setShowCardSave(true);
                    setConfirmOrder(true);
                }
            } else {
                notification['error']({
                    message:
                        'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                });
            }
        });
    };

    const cardStyle = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#32325d',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };

    const handleSaveCard = async (values) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();
        setProcessing(true);

        getAddressByZipcodeApi(values.postal_code).then(async (res) => {
            if (res === undefined) {
                notification['error']({
                    message:
                        'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                });
                return false;
            }
            if (res.CodigoRetorno) {
                let message = 'Ocurrio un error, intentelo de nuevo';
                if (res.CodigoRetorno === '000') {
                    message = 'El código postal no es valido';
                }
                notification['error']({
                    message: message,
                });
                setProcessing(false);
                return false;
            }

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const infoZipCode = res;
            let billing_address = {};

            if (infoZipCode.length > 0) {
                const location = res[0];
                if (location.code_error === 0 && !location.error) {
                    const addressApi = {
                        city: location.Municipio,
                        country: 'México',
                        line1: values.street,
                        postal_code: location.CP,
                        state: location.Entidad,

                        // city: location.response['ciudad'],
                        // country: location.response['pais'],
                        // line1: values.street,
                        // postal_code: location.response['cp'],
                        // state: location.response['estado'],
                    };
                    billing_address = addressApi;
                }
            }

            console.log(billing_address);
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        address: {
                            city: billing_address.city,
                            country: 'MX',
                            line1: billing_address.line1,
                            line2: null,
                            postal_code: billing_address.postal_code,
                            state: billing_address.state,
                        },
                        email: values.email,
                        name: `${values.name} ${values.lastName}`,
                        phone: values.phone,
                    },
                },
            });

            if (result.error) {
                // Display result.error.message in your UI.
                setError(`Tarjeta invalida: ${result.error.message}`);
                setProcessing(false);
            } else {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer

                setError(null);
                setProcessing(false);
                setSucceeded(true);
                setConfirmOrder(true);
                setStripePaymentMethod(result.setupIntent.payment_method);
                stripeMethods();
                setShowBillingInputs(false);
                form.resetFields();
            }
        });
    };

    function selectCard(id) {
        setCardSelect(id);
        setStripePaymentMethod(id);
    }

    return (
        <>
            {isValidCard && !loading && (
                <Form
                    form={form}
                    {...layout}
                    name="nest-messages"
                    className="payment-form"
                    onFinish={handleSaveCard}
                    validateMessages={validateMessages}
                    scrollToFirstError={true}
                >
                    {/* <form onSubmit={handleSaveCard} className="payment-form"> */}
                    {showCardSave &&
                        cardSave.map((cardItem) => {
                            return (
                                <Card
                                    style={{ marginBottom: 20 }}
                                    className={'card-payment'}
                                    key={cardItem.id}
                                    onClick={() => selectCard(cardItem.id)}
                                >
                                    <Row>
                                        <Col span={4}>
                                            <Image
                                                width={25}
                                                src={
                                                    cardItem.card.brand ===
                                                    'visa'
                                                        ? VisaCard
                                                        : cardItem.card
                                                              .brand ===
                                                          'masterCard'
                                                        ? MasterCard
                                                        : AmericaExpress
                                                }
                                            />
                                        </Col>

                                        <Col
                                            span={16}
                                            style={{ textAlign: 'left' }}
                                        >
                                            <p className="mb-0">
                                                ...{cardItem.card.last4}
                                            </p>
                                        </Col>
                                        <Col span={4}>
                                            {cardSelect === cardItem.id && (
                                                <CheckCircleTwoTone
                                                    style={{ fontSize: '20px' }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })}

                    <Divider>Agregar una tarjeta de crédito/débito</Divider>

                    <Button
                        type="primary"
                        onClick={() => setShowBillingInputs(true)}
                    >
                        Agregar Tarjeta
                    </Button>

                    {showBillingInputs && (
                        <>
                            <Title
                                level={4}
                                style={{ marginTop: 15, marginBottom: 10 }}
                            >
                                Datos de Facturación
                            </Title>

                            <CardElement
                                options={cardStyle}
                                onChange={handleChange}
                            />

                            <Divider>Información del propietario</Divider>

                            <Form.Item
                                name={['name']}
                                label="Nombre"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['lastName']}
                                label="Apellido"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['phone']}
                                label="Télefono"
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (
                                                !value ||
                                                value.toString().length === 10
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                'Por favor ingresa tú número telefónico a 10 dígitos'
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['email']}
                                label="Email"
                                rules={[{ type: 'email' }, { required: true }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="postal_code"
                                label="Código Postal"
                                rules={[{ type: 'number', required: true }]}
                            >
                                <InputNumber
                                    parser={(value) =>
                                        value.replace(/\$\s?|(,*)\./g, '')
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="street"
                                label="Dirección"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
                            >
                                <Button
                                    htmlType="submit"
                                    className="mt-20"
                                    disabled={
                                        processing || disabled || succeeded
                                    }
                                    type="primary"
                                    loading={processing}
                                    // onClick={handleSaveCard}
                                >
                                    Guardar Tarjeta
                                </Button>
                            </Form.Item>
                        </>
                    )}

                    {/* Show any error that happens when processing the payment */}
                    {error && (
                        <div className="card-error" role="alert">
                            <br />
                            <Alert type="error" message={error} banner />
                        </div>
                    )}

                    {/* Show a success message upon completion */}
                    {succeeded && (
                        <div className="card-error" role="alert">
                            <br />
                            <Alert
                                type="success"
                                message={'Tarjeta Guardada'}
                                banner
                            />
                        </div>
                    )}
                    {/* </form> */}
                </Form>
            )}
            {!isValidCard && !loading && (
                <Alert
                    type="error"
                    message={'Método de pago no disponible por el momento'}
                    banner
                />
            )}
        </>
    );
};

export default CheckoutForm;
