import React, { useEffect, useState } from 'react';
import { getCompaniesRunner } from '../../../api/runner';
import { getAccessTokenApi } from '../../../api/auth';
import moment from 'moment';
import {
    notification,
    Layout,
    Divider,
    Typography,
    Tabs,
    Alert,
    Skeleton,
    Row,
    Col,
} from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import {
    EnvironmentOutlined,
    PhoneOutlined,
    ClockCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    formatPhoneNumber,
    userLogOut,
    formatMoney,
} from '../../../utils/general';

import './Help.scss';

const { Title, Paragraph, Text } = Typography;

const Help = () => {
    const token = getAccessTokenApi();
    const [infoCompanies, setInfoCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const { Content } = Layout;
    const { TabPane } = Tabs;

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getCompaniesRunner(token).then((response) => {
            console.log(response);
            if (response === undefined || response.error) {
                notification['error']({
                    message: 'Ocurrio un error, intentelo mas tarde',
                });
                return false;
            } else {
                let companiesArray = response.result;

                let daysSchedule = [];
                const now = moment().format('YYYY-MM-DD');

                companiesArray.forEach((companies) => {
                    companies.companySchedules.forEach((day) => {
                        let dayOfWeek = '';

                        switch (day.dayOfWeek) {
                            case 1:
                                dayOfWeek = 'Lunes';
                                break;
                            case 2:
                                dayOfWeek = 'Martes';
                                break;
                            case 3:
                                dayOfWeek = 'Miercoles';
                                break;
                            case 4:
                                dayOfWeek = 'Jueves';
                                break;
                            case 5:
                                dayOfWeek = 'Viernes';
                                break;
                            case 6:
                                dayOfWeek = 'Sábado';
                                break;
                            case 7:
                                dayOfWeek = 'Domingo';
                                break;

                            default:
                                break;
                        }

                        const daySchedule = {
                            companyId: companies.companyId,
                            dayOfWeek: dayOfWeek,
                            NumberOfday: day.dayOfWeek,
                            timeStart: moment(`${now} ${day.timeStart}`),
                            timeEnd: moment(`${now} ${day.timeEnd}`),
                            isClosed: false,
                        };
                        daysSchedule.push(daySchedule);
                    });

                    for (let index = 1; index <= 7; index++) {
                        const found = companies.companySchedules.find(
                            (item) => item.dayOfWeek === index
                        );

                        if (found === undefined) {
                            let dayOfWeek = '';

                            switch (index) {
                                case 1:
                                    dayOfWeek = 'Lunes';
                                    break;
                                case 2:
                                    dayOfWeek = 'Martes';
                                    break;
                                case 3:
                                    dayOfWeek = 'Miercoles';
                                    break;
                                case 4:
                                    dayOfWeek = 'Jueves';
                                    break;
                                case 5:
                                    dayOfWeek = 'Viernes';
                                    break;
                                case 6:
                                    dayOfWeek = 'Sábado';
                                    break;
                                case 7:
                                    dayOfWeek = 'Domingo';
                                    break;

                                default:
                                    break;
                            }
                            const daySchedule = {
                                companyId: companies.companyId,
                                dayOfWeek: dayOfWeek,
                                NumberOfday: index,
                                isClosed: true,
                            };

                            daysSchedule.push(daySchedule);
                        }
                    }
                    companies['companyScheduleComplete'] = daysSchedule;

                    //Group Info -> Asl Api to fill this info
                    // let infoGroup = {
                    //     companyId: companies.companyId,
                    //     groupName: companies.companyName,
                    // };

                    // companies['companyGroup'] = infoGroup;
                });
                setInfoCompanies(companiesArray);
                setLoading(false);

                if (daysSchedule.length === 0) {
                    setShowAlert(true);
                }
            }
        });
    }, [token]);

    return (
        <Layout className="help-runner">
            <Content className="help-runner_content">
                <HeaderTop title={'Ayuda'} backIcon={true} backUrl={'/'} />

                {loading && (
                    <div className="help-runner____container">
                        <Skeleton active />

                        <Divider />

                        <Skeleton active />
                    </div>
                )}

                {!loading && (
                    <>
                        {infoCompanies.length > 0 && (
                            <Tabs defaultActiveKey="0" centered size="large">
                                {infoCompanies.map((company, companyKey) => {
                                    return (
                                        <TabPane
                                            tab={company.companyName}
                                            key={companyKey}
                                        >
                                            <InfoCompany company={company} />

                                            {/* <InfoGroup
                                                group={company.companyGroup}
                                            /> */}
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        )}

                        {showAlert && (
                            <div className="help-runner__container">
                                <Alert
                                    message="No esta afiliado a ningún establecimiento."
                                    description="Favor de ponerse en contácto."
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        )}
                    </>
                )}
            </Content>
        </Layout>
    );
};

export default Help;

function InfoCompany(props) {
    const { company } = props;

    return (
        <>
            <div className="help__container">
                <Title
                    level={3}
                    style={{
                        marginTop: 20,
                        textAlign: 'center',
                    }}
                >
                    {company.companyName}
                </Title>
                <Divider>
                    <EnvironmentOutlined
                        style={{
                            marginRight: 6,
                            color: 'tomato',
                            fontSize: 18,
                        }}
                    />{' '}
                    Dirección
                </Divider>
                <Paragraph className="text-center">
                    {company.companyAddress}
                    {' Col. '} {company.companyNeighborhood}
                    {' CP. '}
                    {company.companyZipCode} {company.companyCity}{' '}
                    {company.companyState}
                </Paragraph>
                {/* <Divider>Horario de atención</Divider> */}
                <Divider>
                    <PhoneOutlined
                        style={{
                            marginRight: 6,
                            color: 'tomato',
                            fontSize: 18,
                        }}
                    />{' '}
                    Télefono
                </Divider>
                <Paragraph className="text-center">
                    {formatPhoneNumber(company.companyPhone)}
                </Paragraph>
                {company.companyPhoneAlternate !== null && (
                    <Paragraph className="text-center">
                        {formatPhoneNumber(company.companyPhoneAlternate)}
                    </Paragraph>
                )}
            </div>
            <div>
                <Divider>
                    <ClockCircleOutlined
                        style={{
                            marginRight: 6,
                            color: 'tomato',
                            fontSize: 18,
                        }}
                    />{' '}
                    Horario
                </Divider>
                {company.companyScheduleComplete.map((day, index) => {
                    return (
                        <div key={index}>
                            {day.companyId === company.companyId && (
                                <Paragraph
                                    className="text-center"
                                    key={day.dayOfWeek}
                                >
                                    {day.dayOfWeek} :{' '}
                                    {day.isClosed
                                        ? 'Cerrado'
                                        : day.timeStart.format('h:mm A') +
                                          ' - ' +
                                          day.timeEnd.format('h:mm A')}
                                </Paragraph>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

function InfoGroup(props) {
    const { group } = props;

    console.log(group);
    return (
        <div className="help__container text-center">
            <Divider>
                <InfoCircleOutlined
                    style={{
                        marginRight: 6,
                        color: 'tomato',
                        fontSize: 18,
                    }}
                />{' '}
                Información del Grupo
            </Divider>
            <Row>
                <Col xs={24} md={8}>
                    <Paragraph>
                        <Text strong>{'Grupo: '}</Text>
                        <Text type="secondary">{group.groupName}</Text>
                    </Paragraph>
                </Col>
                <Col xs={24} md={8}>
                    <Paragraph>
                        <Text strong>{'Pago: '}</Text>
                        <Text type="secondary">${formatMoney(40.5)}</Text>
                    </Paragraph>
                </Col>
                <Col xs={24} md={8}>
                    <Paragraph>
                        <Text strong>{'Kilómetros base (Km): '}</Text>
                        <Text type="secondary">{30}</Text>
                    </Paragraph>
                </Col>
                <Col xs={24} md={8}>
                    <Paragraph>
                        <Text strong>{'Precio base: '}</Text>
                        <Text type="secondary">${formatMoney(20)}</Text>
                    </Paragraph>
                </Col>
                <Col xs={24} md={8}>
                    <Paragraph>
                        <Text strong>{'Precio por km exedido: '}</Text>
                        <Text type="secondary">${formatMoney(5.35)}</Text>
                    </Paragraph>
                </Col>
            </Row>
        </div>
    );
}
